.subscription {
  width: 100%;

  &-container {
    background-color: $bg-form;
    padding: 12px 30px 28px;
    height: auto;
  }

  &-title {
    color: $fontcolor;
    font-size: 36px;
    margin-bottom: 4px;
    text-align: left;

    @media (min-width: 992px) {
      font-size: 30px;
    }

    @media (min-width: 1200px) {
      font-size: 40px;
    }
  }

  &-description {
    line-height: 1.5;
    font-size: 19px;

    @media (min-width: 992px) {
      font-size: 20px;
    }
  }

  &-label {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  &-btn {
    margin: 28px auto 0;
  }
}