.home {
  h1, h2 {
    font-weight: 300;
    text-align: center;
  }

  .hero {
    .hero-details {
      position: relative;

      @media (max-width: 767px) {
        margin-left: -15px;
        margin-right: -15px;
      }

      .content {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(66,75,131, 0.8);
        padding: 7px 20px 20px;
        text-align: center;
        color: white;
        margin: 0;
        z-index: 1;

        h1 {
          margin: 0;
          line-height: 1.05;
        }
      }

      .slide {
        .content {
          @media (max-width: 991px) {
            padding: 7px 20px 15px;
          }

          @media (max-width: 767px) {
            top: calc(50% - 3.6%);
            transform: translateY(-50%);
            padding: 5px 15px 13px;
          }

          @media (max-width: 374px) {
            padding: 5px 10px 13px;
          }
        }

        .hero-bg {
          @media (max-width: 767px) {
            padding-bottom: 132%;
          }
        }
      }
    }
  }

  .intro {
    margin: 50px 0;

    // @media (max-width: 991px) {
    //   margin: 50px -15px;
    // }

    @media (max-width: 767px) {
      margin: 30px -15px 30px;
    }

    .intro-img {
      @media (max-width: 767px) {
        padding: 0;
      }
    }

    .intro-text {
      p {
        margin-top: -8px;
        width: 95%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      .btn {
        @media (max-width: 767px) {
          margin: 10px 0 30px;
        }
      }
    }
  }

  .living-options-holder {
    background: $bg-lt-teal;
    border-bottom: 2px solid $teal;
    border-top: 2px solid $teal;
    padding: 20px 0 30px;
    margin: 0;

    // @media (max-width: 991px) {
    //   margin: 0 -15px;
    // }

    @media (max-width: 767px) {
      padding: 15px 0 5px;
      margin: 30px -15px 0;
      border-top: 0;
    }

    h2 {
      margin-bottom: 25px;

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }

    h3 {
      margin-bottom: 5px;

      @media (max-width: 991px) {
        font-size: 22px;
      }
    }

    .living-option {
      border-top: 3px solid white;
      position: relative;

      &:nth-child(1) {
        border: none;
      }

      @media (max-width: 767px) {
        padding-top: 20px;

        &:nth-child(1) {
          padding-top: 0;
        }
      }

      header {
        @media (max-width: 767px) {
          position: absolute;
          height: 31px;
          top: -31px;
          width: calc(100% - 30px);
          text-align: center;
          background: rgba(66,75,131, 0.85);
          padding: 7px 5px;
        }

        h3 {
          @media (max-width: 767px) {
            color: white;
            margin: 0;
            font-size: 16px;
            line-height: 1;
          }
        }
      }

      .living-details {
        padding-top: 30px;

        @media (max-width: 991px) {
          padding-top: 5px;
        }

        @media (max-width: 767px) {
          padding: 10px 15px;
        }

        p {
          padding-right: 30px;
          color: $blue;
          @extend .font-reg;
          letter-spacing: 0;

          @media (max-width: 991px) {
            padding-right: 0;
          }

          @media (max-width: 767px) {
            margin-bottom: 5px;
          }
        }

        a {
          @extend .link-orange;
          color: $link-bg-orange;
          font-weight: 700;
          font-size: 18.66px;

          @media (max-width: 991px) {
            margin: 5px 0;
          }
        }
      }
    }
  }

  .juicer {
    margin: 30px 0 50px;

    @media (max-width: 767px) {
      margin: 20px -15px 30px;
    }
  }

  .locations {
    padding: 20px 0 40px;
    background: $bg-gray;
    border-bottom: 2px solid $blue;
    border-top: 2px solid $blue;
    margin: 0;

    @media (max-width: 767px) {
      margin: 0 -15px;
      padding: 15px 0 25px;
      border-top: none;
    }

    header {
      margin-bottom: 15px;

      h2 {
        margin-bottom: 0;

        @media (max-width: 767px) {
          margin-bottom: 5px;
        }
      }

      p {
        @extend .font-reg;
        margin: 0 0 15px 0;
        text-align: center;

        @media (max-width: 767px) {
          text-align: left;
          font-weight: 300;
        }
      }
    }

    .map-holder {
      position: relative;
      padding-left: 0;

      @media (max-width: 767px) {
        padding-right: 0;
      }

      #map-canvas {
        width: 100%;
        padding-bottom: 80%;
        min-height: 500px;
        margin-bottom: 20px;

        @media (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      .map-legend {
        position: relative;
        left: 50%;
        bottom: 0;
        z-index: 1000;
        transform: translateX(-50%);
        display: inline-block;
        padding: 10px 35px 10px 15px;
        background: white;

        @media (max-width: 767px) {
          position: static;
          transform: none;
          width: 100%;
          padding: 10px 15px;
        }

        .legends {
          @media (max-width: 767px) {
            width: 320px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 374px) {
              position: static;
              transform: none;
            }
          }
        }

        p {
          margin: 0;
          line-height: 1.75;

          @media (max-width: 767px) {
            float: left;
            line-height: 1.35;
            font-size: 17px;
          }

          @media (max-width: 374px) {
            font-size: 15px;
            line-height: 1.5;
          }

          &.coming-soon {
            @media (max-width: 767px) {
              margin-left: 15px;
            }

            &:before {
              background-position: 0 -31px;
            }
          }

          &:before {
            content: '';
            @extend .icon;
            width: 17px;
            height: 25px;
            margin-right: 10px;
            margin-bottom: -5px;
            background-position: -28px -31px;

            @media (max-width: 767px) {
              margin: -3px 5px 0 0;
              vertical-align: middle;
              top: auto;
            }
          }
        }
      }

    }

    .location-form-holder {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          line-height: 1.5;
          padding: 0;

          a {
            color: $fontcolor;

            &:hover, &:focus {
              color: $blue-hover;
            }
          }

          &.location {
            margin-top: 20px;
            @extend .font-reg;
            text-transform: uppercase;
            color: $link-bg-orange;
            font-weight: 700;
            line-height: 1.25;

            @media (max-width: 767px) {
              margin-bottom: 5px;
              font-size: 18.66px;
            }

            &.florida, &.texas {
              margin-top: 0;
            }
          }
        }
      }

      .location-form {
        @extend .font-reg;
        margin: 0 0 20px 0;

        @media (max-width: 767px) {
          margin: 15px 0;
        }

        input[type="text"] {
          border: 2px solid $blue;
          background: white;
          padding: 0 10px;
          height: 44px;
          line-height: 44px;
          float: left;
          max-width: 170px;

          @media (max-width: 767px) {
            // font-size: 14px;
            border: 1px solid $blue;
            height: 32px;
            line-height: 32px;
          }
        }

        button {
          float: left;
          height: 44px;
          line-height: 44px;
          max-width: 45%;
          margin: 0 0 0 30px;
          padding: 0 15px;

          @media (max-width: 767px) {
            height: 32px;
            line-height: 32px;
          }
        }
      }
    }

  }

  .happenings {
    padding: 30px 15px 15px;

    @media (max-width: 991px) {
      padding: 30px 0 15px;
    }

    @media (max-width: 767px) {
      padding: 20px 0 5px;
    }

    .happening {
      &:hover, &:focus {
        .details {
          .title {
            color: $dk-orange;
            font-weight: 400;
          }
        }
      }

      .details {
        margin: 8px 0;

        @media (max-width: 767px) {
          margin-bottom: 15px;
        }

        p {
          line-height: 1.5;

          @media (max-width: 991px) {
            font-size: 17px;
            line-height: 1.25;
          }

          @media (max-width: 767px) {
            font-size: 17px;
          }

          &.cat {
            @extend .font-reg;
            font-size: 16px;
            line-height: 1.25;
            text-transform: uppercase;
            margin-bottom: 0;
            color: $blue;

            @media (max-width: 767px) {
              letter-spacing: 1px;
            }
          }

          &.title {
            color: $blue;
          }
        }
      }
    }

    .btn {
      margin: 15px auto;
    }
  }
}
