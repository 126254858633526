/* Forms */
input {
  margin: 0;
  border: none;
  color: $fontcolor;
}

.gform_wrapper {

  &.lp-contact_wrapper {
    margin: 0;

    .lp-contact {
      .gform_footer {
        padding-top: 5px;
      }
    }
  }

  div.validation_error {
    color: $location-nav-orange !important;
    font-size: 18.66px;
    line-height: 1.25;
    margin-bottom: 15px;
    font-weight: 700 !important;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid $location-nav-orange !important;
    border-bottom: 1px solid $location-nav-orange !important;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0;

    &.gform_fields {
      li.gfield {
        padding: 0 !important;

        .ginput_container {
          margin: 0;
        }

        .ginput_container_select {
          position: relative;

          &:after {
            content: '';
            @extend .icon;
            width: 15px;
            height: 10px;
            background-position: -55px -41px;
            position: absolute;
            right: 10px;
            top: 12px;
          }
        }

        select {
        	display: block;
        	font-weight: 300;
          font-size: 18px;
        	color: $fontcolor;
        	height: 34px;
          line-height: 34px;
        	padding: 0 10px;
        	width: 100%;
        	max-width: 100%;
        	margin: 0;
        	border-radius: 0;
          border: none;
        	-moz-appearance: none;
        	-webkit-appearance: none;
        	appearance: none;
        	background-color: #fff;
        	background-repeat: no-repeat, repeat;
        	background-position: right .7em top 50%, 0 0;
        	background-size: .65em auto, 100%;

          &::-ms-expand {
          	display: none;
          }

          &:hover {
          	border-color: #888;
          }

          &:focus {
          	border-color: #aaa;
          	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
          	box-shadow: 0 0 0 3px -moz-mac-focusring;
          	color: #222;
          	outline: none;
          }

          option {
          	font-weight: 400;
          }

          *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
          	background-position: left .7em top 50%, 0 0;
          	padding: 5px 10px;
          }

          /* Disabled styles */
          &:disabled, &[aria-disabled=true] {
          	color: $fontcolor;
          	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
          	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
          }
          &:disabled:hover, &[aria-disabled=true] {
          	border-color: $fontcolor;
          }
        }

        &.footnote {
          margin-top: 0 !important;

          p {
            font-size: 16px;
          }
        }

        &.touch-captcha {
          margin-top: 25px !important;
        }
      }
    }

    li.gfield {
      margin: 7px 0 0 !important;

      // .gfield_description {
      //   line-height: 1.25;
      //   font-size: 20px;
      //
      //   @media (max-width: 991px) {
      //     font-size: 17px;
      //   }
      //
      //   @media (max-width: 767px) {
      //     font-size: 15px;
      //     line-height: 1.5;
      //   }
      // }

      .gfield_checkbox {
        li {
          vertical-align: middle;
        }
        input {
          width: auto;
          display: inline-block;
        }
        label {
          display: inline-block;
        }
      }

      .gfield_checkbox {
        li {
          position: relative;
          padding-left: 30px;

          input[type="checkbox"]:focus + label::before {
              outline: rgb(59, 153, 252) auto 5px;
          }

          label {
            &:after {
              content: "";
              background: white;
              width: 20px;
              height: 20px;
              display: block;
              position: absolute;
              border: 1px solid $fontcolor;
              left: 0;
              top: 6px;
              border-radius: 0;
              z-index: 0;

              @media (max-width: 1199px) {
                top: 2px;
              }

              @media (max-width: 991px) {
                top: 2px;
              }

              @media (max-width: 767px) {
                top: 2px;
              }
            }

            &:before {
              content: "";
              display: block;
              position: absolute;
              width: 8px;
              height: 15px;
              border: solid $blue;
              border-width: 0 3px 3px 0;
              left: 6px;
              top: 6px;
              transform: rotate(45deg);
              opacity: 0;
              z-index: 1;
              transition: all 100ms;
            }
          }
        }

      }

      input[type="checkbox"] {
        opacity: 0;
        outline: none;
        z-index: 100;
        width: 27px;
        height: 27px;
        top: 0;
        left: 0;
        position: absolute;
        appearance: none;
        cursor: pointer;

        @media (max-width: 991px) {
          width: 24px;
          height: 24px;
        }

        + label {
          cursor: pointer;
        }

        &:focus + label {
          &:after {
            outline: rgb(0, 95, 204) auto 5px;
            border-radius: 0;
          }
        }

        &:checked + label {
          &:before {
            opacity: 1;
            top: 6px;

            @media (max-width: 991px) {
              top: 3px;
            }
          }
        }

      }

      input {
        width: 100%;
        height: 34px;
        line-height: 34px;
        font-size: 18px !important;
        padding: 5px 10px;
        font-weight: 300;

        &[type="text"] {
          padding: 5px 10px;
        }

        @media (max-width: 767px) {
          padding: 6px 5px;
        }
      }

      input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
        padding: 5px 10px;
        line-height: 34px;
        min-height: 34px;
        border-radius: 0;
      }

      textarea {
        border-radius: 0;
      }

      &.gform_validation_container {
        display: none;
      }

      &.gfield_error {
        div.ginput_container {
          margin: 0 !important;
        }

        input, input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
          border: 1px solid $location-nav-orange;
        }

        .gfield_label {
          color: $location-nav-orange;
          font-weight: 700;

          .gfield_required {
            color: $location-nav-orange;
          }
        }

        .gfield_checkbox li label {
          &:after {
            border: 1px solid $location-nav-orange;
          }
        }
      }

      .validation_message {
        color: $location-nav-orange;
        font-size: 18.66px;
        line-height: 1.2;
        padding: 5px 0;
        font-weight: 700;
      }

      .gfield_label {
        margin-bottom: 0;
        @extend .font-reg;

        .gfield_required {
          color: $fontcolor;
          margin: 0;
        }
      }
    }
  }

  .form-input-required {
    @extend .font-reg;
    font-size: 16px!important;
  }

  .gform_footer {
    text-align: center;
    margin-top: 10px;

    input[type="submit"], input.button {
      margin: 0 auto 20px;
      @extend .btn;

      @media (max-width: 991px) {
        width: auto !important;
      }
    }
  }

  .gform_page_footer {
    input[type=submit], input.button {
      @extend .btn;
    }
  }
}

.gform_confirmation_wrapper {
  font-weight: 400;
  margin: 25px 0;
  text-align: center;
}

img.gform_ajax_spinner {
  width: 30px;
  height: auto;
  padding-left: 15px;
}
