footer {
  padding-top: 20px;

  .footer-nav-holder {
    margin: 0 0 25px 0;

    @media (max-width: 767px) {
      margin: 0 -15px 20px;
    }
  }

  .footer-nav {
    ul {
      margin: 0;
      padding: 0;

      br {
        display: none;
      }

      @media (max-width: 991px) {
        margin-top: 20px;
        text-align: left;
      }

      @media (max-width: 767px) {
        text-align: left;
        margin-top: 25px;

        br {
          display: block;
        }
      }

      li {
        color: $blue;
        font-weight: 400;
        display: inline-block;

        &:before {
          content: ' | ';
        }

        &:nth-child(1) {
          a {
            padding-left: 0;
          }

          &:before {
            content: '';
          }
        }

        &:last-child {
          a {
            padding-right: 0;
          }
        }

        @media (max-width: 400px) {
          float: left;

          &.break {
            clear: left;
          }

          &:nth-child(3) {
            &:after {
              content: '';
              display: inline-block;
              width: 30px;
            }
          }
          &:nth-child(4) {
            a {
              padding-left: 0;
            }

            &:before {
              content: '';
            }
          }
        }

        a {
          color: $blue;
          font-size: 16px;
          font-weight: 400;
          display: inline-block;
          padding: 0 10px;
          text-decoration: none;
          text-transform: uppercase;

          @media (max-width: 1199px) {
            // font-size: 14px;
          }

          @media (max-width: 991px) {
            // font-size: 13px;
          }

          @media (max-width: 767px) {
            // font-size: 12px;
            padding: 0 7px;
          }

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }

  .copyright {
    margin: 0;
    background: $bg-gray;

    @media (max-width: 600px) {
      padding-bottom: 40px;
    }

    @media (max-width: 991px) {
      margin: 0 -15px;
    }

    .inner {
      padding: 20px 15px;

      @media (max-width: 991px) {
        padding: 20px 15px;
      }

      p {
        font-size: 16px;
        line-height: 1.25;

        @media (max-width: 991px) {
          order: 12;
        }

        @media (max-width: 767px) {
          margin-bottom: 0;
        }

        br {
          display: none;
        }

        @media (max-width: 767px) {
          // font-size: 12px;

          span {
            display: none;
          }

          br {
            display: block;
          }
        }

        a {
          border: none;

          @media (max-width: 767px) {
            display: inline-block;

            &:after {
              content: '|';
              color: $fontcolor;
              margin: 0 0 0 5px;
              display: inline-block;
            }

            &:last-child(){
              &:after {
                display: none;
              }
            }

            &:active {
              color: $gray;
            }
          }
        }

      }

      a {
        color: $gray;

        @media (max-width: 767px) {
          // margin-top: 10px;
          display: inline-block;
        }

        &:active {
          color: $gray;
        }

        &:hover {
          color: $orange;
        }
      }
    }
  }

  .icons-holder {
    padding-left: 15px;
    order: 3;

    @media (max-width: 991px) {
      order: 1;
      padding: 0;
      width: 100%;
      margin-bottom: 8px;
    }

    a {
      margin-top: 0;
      width: 22px;
      height: 22px;
      display: block;

      &:active {
        color: $gray;
      }
    }

    .icon {
      width: 22px;
      height: 22px;
      margin-left: 5px;

      &.eoh {
        background-position: -58px 0;

        @media (max-width: 767px) {
          margin-left: 0;
        }
      }

      &.accessibility {
        background-position: -114px 0;
      }

      &.pet {
        background-position: -88px 0;
      }

      &.facebook {
        cursor: pointer;
        background-position: -142px 0;

        &:active {
          background-position: -142px 0;
        }

        &:hover {
          background-position: -142px -31px;
        }
      }
    }
  }
}
