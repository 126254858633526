.blog-single {

  .header {
    color: $blue;

    @media (min-width: 992px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .category {
      color: $orange;
      font-weight: 700;
      text-transform: uppercase;
    }

    .entry-title {
      text-align: left;
      font-size: 39px;
      line-height: 1.1;

      @media (min-width: 992px) {
        font-size: 50px;
      }
    }
  }

  .blog-container {
    justify-content: space-between;
    margin: 50px -15px 0;

    @media (min-width: 992px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: 767px) {
      margin: 30px -15px 0;
    }
  }

  .blog-video {
    @media (max-width: 767px) {
      margin: 0 -15px;
    }
  }

  .video-wrapper iframe {
    padding: 15px;
    background-color: $bg-form;

    @media (max-width: 767px) {
      padding: 15px 0;
    }
  }

  .hero .slide {
    padding: 15px;
    background-color: $bg-form;

    @media (max-width: 767px) {
      padding: 15px 0;
    }

    .hero-bg {
      padding-bottom: 55.5%;
    }
  }

  .blog-content {

    @media (min-width: 992px) {
      max-width: 58.333333%;
    }

    p {
      margin-bottom: 20px;
    }
  }

  .subscription {
    @media (min-width: 576px) {
      max-width: 400px;
    }

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }

    form {
      position: relative;
      margin-top: 15px;

      .form-container {
        align-items: center;
        justify-content: center;

        @media (max-width: 767px) {
          flex-direction: column;
        }
      }

      .subscription-label {
        display: block;
        margin-bottom: 10px;
      }

      input {
        width: 100%;
        height: 34px;
        line-height: 34px;
        font-size: 18px !important;
        padding: 5px 10px;
        font-weight: 300;

        @media (max-width: 767px) {
          padding: 6px 5px;
        }

        &.mce_inline_error {
          border: 1px solid red;
        }
      }

      div.mce_inline_error {
        color: red;
        font-size: .8em;
        line-height: 1.2;
        margin-top: 5px;
      }
    }
  }

  // Move out of .home and .blog-single so it can be shared across pages
  .happenings {
    margin: 5px -15px;
    padding: 0;

    h2 {
      text-align: center
    }

    .happening {

      &:hover, &:focus {
       .details {
         .title {
           color: $orange;
         }
       }
     }

      .details {
        margin: 8px 0;

        p.cat {
          font-size: 16px;
          line-height: 1.25;
          text-transform: uppercase;
          margin-bottom: 0;
          font-weight: 400;
          color: $blue;
        }

        p.title {
          color: $blue;
        }

        .btn {
          margin: 15px auto;
        }
      }
    }
  }
}

/* Events Manager Pro */
.single-event {
  .blog-single {
    .header {
      padding-top: 25px;
    }
  }
}

.em-location-desc {
  margin-top: 15px;

  .welcome-center {
    color: $blue;
    text-transform: uppercase;
    font-weight: 400;
  }
}

/* Contact */
.booking-form {
  margin-top: 15px;

  .inner {
    padding: 20px 7%;
    background: $bg-form;

    @media (max-width: 991px) {
      padding: 25px 15px;
    }

    @media (max-width: 767px) {
      padding: 25px 7%;
    }
  }

  h3 {
    text-align: center;
    font-size: 1.7em;

    @media (max-width: 767px) {
      font-size: 35px;
    }

    @media (max-width: 374px) {
      font-size: 26px;
    }
  }

  label {
    line-height: 1.25;
    font-size: 20px;
    margin-bottom: 3px;

    @media (max-width: 991px) {
      font-size: 17px;
    }

    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 1.5;
    }
  }

  .em-booking-form-details {
    width: 100%;
    padding: 0;

    p {
      margin-bottom: 15px;
    }

    label {
      width: 100%;
      color: $gray;
      font-weight: 400;
    }

    .input {
      width: 100%;
      padding: 3px 5px;

      @media (max-width: 767px) {
        padding: 6px 5px;
      }
    }

    textarea {
      min-height: 100px;
      border: none;
    }
  }

  select {
    border: none;
    color: $gray;
    font-weight: 400;
    padding: 3px 5px;
  }

  input[type="submit"] {
    border-radius: 0;
    padding: 7px 15px;
    background: $blue;
    color: white;
    border: none;
    text-transform: uppercase;
    @extend .font-reg;
    font-size: 18.66px;
    letter-spacing: 1px;
    margin: 10px 0 0;
    font-weight: 700;

    @media (max-width: 991px) {
      // font-size: 15px;
      padding: 5px 15px;
    }

    @media (max-width: 767px) {
      // font-size: 14px;
      padding: 5px 15px;
    }

    &:hover {
      color: white;
      background: $blue-hover;
    }

    &.orange {
      background: $orange;

      &:hover {
        color: white;
        background: $blue;
      }
    }
  }
}

#em-booking {
  div.em-booking-message-error {
    color: red;
    line-height: 1.25;
    margin-bottom: 15px;
    font-size: .9em;
    text-align: center;
    padding: 10px 0;
    border: 1px solid red;
    border-left: none;
    border-right: none;
    background: none;

    p {
      color: red;
    }
  }

  .em-booking-buttons {
    margin-top: 20px;
    text-align: center;

    input {
      &:hover, &:focus {
        color: white;
      }
    }
  }
}
