.communities {
  &.community-events {

    .hero {
      .hero-details {
        position: relative;

        .slide {
          .content {

            @media (max-width: 767px) {
              top: calc(50% - -7%);
              transform: translateY(-50%);
              padding: 15px;
            }

            @media (max-width: 374px) {
              padding: 5px 10px;
            }
          }

          .hero-bg {
            padding-bottom: 25.5%;

            @media (max-width: 767px) {
              padding-bottom: 87%;
            }
          }
        }
      }
    }

    .events-content {
      background: $bg-gray;
      margin: 0;
      padding: 30px 0 0;

      @media (max-width: 767px) {
        margin: 0 -15px;
        padding: 20px 0 25px;
      }
    }

    .events {
      margin: 30px 0;

      @media (max-width: 767px) {
        padding: 10px 0 30px;
      }

      .events-header {
        border-bottom: 1px solid $teal;
        vertical-align: middle;
        padding-bottom: 15px;
        position: relative;
        padding-left: 50px;
        margin-bottom: 15px;

        &:before {
          content: '';
          position: absolute;
          display: inline-block;
          width: 42px;
          height: 44px;
          top: 0;
          left: 0;
          background: url(img/sprites1.png) no-repeat 0 0;
          background-size: 240px 240px;
        }

        h2 {
          font-size: 1.7em;
          line-height: 1.25;
          font-weight: 300;
          text-transform: uppercase;
          color: $gray;
          text-align: left;
          margin: 0;

          @media (max-width: 991px) {
            padding: 7px 0 0;
          }

          @media (max-width: 767px) {
            font-size: 26px;
            padding: 9px 0 5px;
          }

          @media (max-width: 374px) {
            font-size: 24px;
          }
        }
      }

      .no-events {
        display: none;
      }

      .events-list {
        margin-bottom: 20px;

        .event {
          a {
            @extend .font-reg;
            font-size: 1.15em;
            display: block;
            margin-bottom: 10px;

            &:after {
              content: '>';
              display: inline-block;
              position: relative;
              top: -1px;
              padding-left: 5px;
            }
          }

          .event-timing {
            color: $blue;
            @extend .font-reg;
            font-size: 1.2em;
            margin-bottom: 0;

            br {
              display: none;

              @media (max-width: 767px) {
                display: block;
              }
            }

            span {
              padding: 0 10px;

              @media (max-width: 767px) {
                &.mobile-hidden {
                  display: none;
                }
              }
            }

            @media (max-width: 991px) {
              font-size: 18px;
            }

            @media (max-width: 767px) {
              font-size: 18px;
              line-height: 1.35;
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .juicer {
      margin: 35px 0 30px;

      @media (max-width: 767px) {
        margin: 20px -15px 15px;
      }
    }
  }
}

.single-event {
  .booking-form {
    input, textarea {
      &:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
      }
    }

    select {
      display: block;
      font-size: 18px;
      color: $fontcolor;
      height: 34px;
      line-height: 34px;
      padding: 0 10px;
      width: 100%;
      max-width: 100%;
      margin: 0;
      border-radius: 0;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%;

      &::-ms-expand {
        display: none;
      }

      &:hover {
        border-color: #888;
      }

      &:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
      }

      option {
        font-weight: 400;
      }

      *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
        background-position: left .7em top 50%, 0 0;
        padding: 5px 10px;
      }

      /* Disabled styles */
      &:disabled, &[aria-disabled=true] {
        color: $fontcolor;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
          linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
      }
      &:disabled:hover, &[aria-disabled=true] {
        border-color: $fontcolor;
      }
    }

    .em-booking-form-details {
      p {
        &.input-dbem_country, &.em-tickets-spaces {
          position: relative;

          &:after {
            content: '';
            @extend .icon;
            width: 15px;
            height: 10px;
            background-position: -55px -41px;
            position: absolute;
            right: 10px;
            top: 37px;
          }

        }
      }
    }

  }
}
