/* Careers */
.careers {
  section {
    &.locations {
      margin: 50px 0px;

      .locations-list {
        @media (max-width: 767px) {
          margin: 15px 0 0;
        }
      }
    }
  }
}
