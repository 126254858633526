.communities {
  &.community-schedule {

    section.hero {
      .hero-bg {
        padding-bottom: 25.8%;
      }

      @media (max-width: 767px) {
        .hero-bg {
          padding-bottom: 132%;
        }
        .content {
          top: calc(50% - 9.5%);
        }
      }
    }

    .schedule-holder {
      @media (max-width: 991px) {
        margin: 50px 0 30px;
      }

      @media (max-width: 767px) {
        margin: 25px -15px 10px;
      }
    }

    .gform_wrapper {
      margin: 0 0 10px;
    }

    .gform_confirmation_wrapper {
      background-color: $bg-form;
      padding: 100px 30px 200px;
      margin: 0;
      color: $blue;
      font-size: 30px;
      font-weight: 300;
      line-height: 1.25;

      @media (max-width: 767px) {
        margin: 25px 0 5px;
        padding: 70px 15px 120px;
        font-size: 22px;
      }

      img {
        max-width: 250px;
        margin-right: 50px;

        @media (max-width: 767px) {
          max-width: 175px;
        }
      }

      .reset-link {
        margin: 10px 0 0;
      }

      a {
        @extend .inline-link;
      }
    }

    .utility {
      margin-top: 20px;

      @media (max-width: 767px) {
        margin: 20px 0 30px;
      }
    }

    a.link-orange {
      text-transform: none;
      &::after {
        display: none;
      }
      &::before {
        content: "";
        background: url(img/phone-icon.png);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 12.5px;
        height:20px;
        color: $orange;
        font-weight: bold;
        display: inline-flex;
        margin-right: 10px;
      }
    }

    .btn {
      font-size: 14px;
      width: 170px;
      text-align: left;
      display: table;
      padding: 10px 15px;
      margin-top: 30px;
      position: relative;

      &.chat-btn {
        margin-top: 0;
        &:after {
          content: '';
          @extend .icon;
          width: 30px;
          height: 24px;
          background-position: -55px -102px;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }

    form.gform-schedule-visit {
      background-color: $bg-form;
      padding: 25px 30px 20px;

      @media (max-width: 991px) {
        padding: 25px 15px;
      }

      ul li.gfield  {
        padding-top: 0!important;
        margin-top: 0!important;
        margin-bottom: 10px!important;

        &.gfield_html {
          margin-bottom: 0!important;
        }

        @media (min-width: 641px) {
          &.gf_left_half {
            padding-right: 20px!important;
          }
        }

        .ginput_container_text {
          margin-top: 0 !important;
        }
      }

      textarea.medium {
        border: none !important;
      }

      .gform_footer {
        margin-top: 10px !important;

        @media (max-width: 991px) {
          margin: 0 !important;
        }
      }

      input[type="submit"] {
        width: auto !important;
        margin-top: 0 !important;
        font-size: 20px!important;
        min-height: 0 !important;

        @media (max-width: 767px) {
          margin: 0 auto;
          line-height: 1 !important;
          font-size: 16px!important;
        }
      }
    }

    .schedule-form-holder {
      i {
        font-size: 16px;
        display: block;
        text-align: right;

        @media (max-width: 767px) {
          text-align: center;
          padding: 0 10px;
        }
      }
    }
  }
}
