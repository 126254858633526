html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  font-family: 'europa', sans-serif;
  font-weight: 300;
  width: 100%;
  height: 100%;
  color: $fontcolor;
  background: white;
  font-size: 20px;
  line-height: 1.5;

  //&.modal-open {
  //  padding-right: 0px !important;
  //}

  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 1.5;
  }

  @media (max-width: 767px) {
    font-size: 18.66px;
    line-height: 1.5;
  }
}

/* Typography */
h1, h2, h3, h4 {
  line-height: 1.5;
}

h1 {
  font-size: 59px;
  font-weight: 300;
  letter-spacing: .00025em;
  color: $blue;

  @media (max-width: 991px) {
    font-size: 42px;
  }

  @media (max-width: 767px) {
    font-size: 39px;
  }

  @media (max-width: 374px) {
    font-size: 34px;
  }

  span {
    &.opening-text {
      font-size: 44px;

      @media (max-width: 991px) {
        font-size: 36px;
        display: block;
      }

      @media (max-width: 767px) {
        font-size: 29px;
      }
    }
  }
}

h2 {
  font-size: 50px;
  color: $blue;
  font-weight: 300;

  @media (max-width: 991px) {
    font-size: 36px;
  }

  @media (max-width: 767px) {
    font-size: 33px;
  }
}

h3 {
  font-size: 30px;
  color: $blue;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 5px;

  @media (max-width: 991px) {
    font-size: 23px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
  }
}

h5 {
  font-size: 24px;
  color: $blue;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0 0 15px;
  padding-top: 15px;

  @media (max-width: 767px) {
    font-size: 22px;
  }
}

h6 {
  font-size: 20px;
  color: $orange;
  font-weight: 700;
  margin: 0 0 5px;

  @media (max-width: 767px) {
    font-size: 18.66px;
  }
}

.font-reg {
  font-family: 'europa', sans-serif;
  font-weight: 400;
}

.font-bold, strong {
  font-family: 'europa', sans-serif;
  font-weight: 700;
}

.myriad {
  font-family: 'myriad-pro', sans-serif;
  font-weight: 300;
}

/* Global Styles */
nav {
  ul {
    list-style-type: none;
    padding: 0;

    li:before {
      display: none;
    }
  }
}

.default ul {
  list-style: none;
  padding: 0 0 0 20px;

  li:before {
    content: "\2022";
    color: $orange;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

p {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.75;

  @media (max-width: 991px) {
    font-size: 18.66px;
    line-height: 1.5;
  }

  @media (max-width: 767px) {
    font-size: 18.66px;
    line-height: 1.5;
  }

  a {
    color: $blue;
    border-bottom: 1px solid $blue;
    font-weight: 400;

    &:hover {
      color: $dk-orange;
      border-bottom: 1px solid $dk-orange;
    }
  }
}

img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

a {
  color: $blue;
  text-decoration: none;

  &:hover {
    color: $blue-hover;
    text-decoration: none;
  }

  &.inline-link {
    font-weight: 700;
  }
}

::placeholder {
  color: $fontcolor;
  font-weight: 400;
}

/* Global Classes */
.site-container {
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1230px;
}

.content-main {
  padding-top: 145px;
  min-height: 500px;

  &.communities {
    padding-top: 199px;

    @media (max-width: 991px) {
      padding-top: 183px;
    }

    @media (max-width: 767px) {
      padding-top: 141px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 100px;
    min-height: 300px;
  }
}

.slide {
  position: relative;
}

.hero {
  h1 {
    color: white;
  }
}

.hero-bg {
  width: 100%;
  padding-bottom: 36%;
  background-size: cover;
  background-repeat: no-repeat;
}

.icon {
  background: url(img/sprites.png) no-repeat 0 0;
  background-size: $spritesize;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.fontawesome {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.bg-gray {
  background: $bg-gray;
}

.text-orange {
  color: $link-orange;
}

.link-orange {
  color: $link-orange;
  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  margin: 20px 0;
  display: inline-block;

  @media (max-width: 991px) {
    margin: 10px 0;
  }

  @media (max-width: 767px) {
    margin: 5px 0;
  }

  &:after {
    content: ' >';
    position: relative;
    top: -1px;
  }

  &:hover {
    color: $link-orange-hover;
  }
}

.link-bg-orange {
  @extend .link-orange;
  color: $link-bg-orange;

  &:hover {
    color: $link-bg-orange-hover;
  }
}

.video-wrapper, .map-wrapper {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.inner {
  padding: 15px;
}

.centered {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.no-break {
  white-space: nowrap;
}

.btn, .btn-submit {
  border-radius: 0;
  padding: 7px 15px;
  background: $blue;
  color: white;
  border: none;
  text-transform: uppercase;
  @extend .font-reg;
  font-size: 18.66px;
  letter-spacing: 1px;
  margin: 25px 0;
  font-weight: 700;

  @media (max-width: 991px) {
    // font-size: 15px;
    padding: 5px 15px;
  }

  @media (max-width: 767px) {
    // font-size: 14px;
    padding: 5px 15px;
  }

  &:hover {
    color: white;
    background: $blue-hover;
  }

  &.orange {
    background: $orange;

    &:hover {
      color: white;
      background: $blue;
    }
  }
}

/* Search Results */
.search-results {
  margin: 0;

  @media (max-width: 767px) {
    > div {
      padding: 0;
    }
  }

  h1 {
    @media (max-width: 767px) {
      margin: 10px 0 0;
    }
  }

  p.results-details {
    line-height: 24px;

    span {
      font-weight: 400;
      color: $blue;
      text-transform: uppercase;
    }
  }

  .search-result {
    margin-top: 25px;

    @media (max-width: 767px) {
      margin-top: 10px;
    }

    p {
      line-height: 1.25;
    }

    .title {
      font-size: 24px;
      color: $blue;
      @extend .font-reg;
      margin: 0;
    }

    .link-orange {
      // font-size: 16px;
      font-weight: 700;
      margin: 0;
    }

    // .searchform {
    //   @extend .font-reg;
    //   @extend .clearfix;
    //   margin: 20px 0 30px;
    //
    //   @media (max-width: 767px) {
    //     margin: 30px 0 15px;
    //   }
    //
    //   .screen-reader-text {
    //     @extend .sr-only;
    //   }
    //
    //   input[type="text"] {
    //     border: 2px solid $blue;
    //     background: white;
    //     padding: 0 10px;
    //     height: 44px;
    //     line-height: 44px;
    //     float: left;
    //     max-width: 270px;
    //
    //     @media (max-width: 767px) {
    //       font-size: 14px;
    //       border: 1px solid $blue;
    //       height: 32px;
    //       line-height: 32px;
    //     }
    //   }
    //
    //   #searchsubmit {
    //     @extend .btn;
    //     float: left;
    //     height: 44px;
    //     line-height: 44px;
    //     max-width: 45%;
    //     margin: 0 0 0 15px;
    //     padding: 0 15px;
    //
    //     @media (max-width: 767px) {
    //       height: 32px;
    //       line-height: 32px;
    //     }
    //   }
    // }
  }
}

.searchform {
  @extend .font-reg;
  @extend .clearfix;
  margin: 20px 0 30px;

  @media (max-width: 767px) {
    margin: 30px 0 15px;
  }

  .screen-reader-text {
    @extend .sr-only;
  }

  input[type="text"] {
    border: 2px solid $blue;
    background: white;
    padding: 0 10px;
    height: 44px;
    line-height: 44px;
    float: left;
    max-width: 270px;

    @media (max-width: 767px) {
      font-size: 14px;
      border: 1px solid $blue;
      height: 32px;
      line-height: 32px;
    }
  }

  #searchsubmit {
    @extend .btn;
    float: left;
    height: 44px;
    line-height: 44px;
    max-width: 45%;
    margin: 0 0 0 15px;
    padding: 0 15px;
    font-weight: 700;

    @media (max-width: 767px) {
      height: 32px;
      line-height: 32px;
    }
  }
}

/* Pagination */
.pagination {
  text-align: center;
  margin: 20px auto;
  display: block;

  .page-numbers  {
    @extend .font-reg;

    &.current {
      color: $orange;
      font-weight: 700;
    }
  }
}

ul.lcp_paginator {
  text-align: center;
  margin: 20px auto;
  display: block;

  li {
    border: none;
    @extend .font-reg;
    padding: 0;

    &.lcp_currentpage {
      font-weight: 700;
      color: $orange;
    }

    &:before {
      display: none;
    }
  }
}

/* Slick Slider */
.slick-arrow {
  display: block;
  width: 42px;
  height: 65px;
  background: url(images/sprites.png) no-repeat -62px 0;
  background-size: 250px 250px;
  position: absolute;
  left: -56px;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background-position: -62px -78px;
  }

  @media (max-width: 1199px) {
    background-size: 150px 150px;
    background-position: -35px 0;
    left: -36px;
    width: 28px;
    height: 40px;
    padding: 0;

    &:hover {
      background-position: -35px -47px;
    }
  }

  &.slick-next {
    right: -56px;
    left: auto;
    background-position: -134px 0;

    &:hover {
      background-position: -134px -78px;
    }

    @media (max-width: 1199px) {
      background-position: -80px 0;
      right: -36px;

      &:hover {
        background-position: -80px -47px;
      }
    }
  }
}

/* Juicer IO */
.juicer-wrapper {
  position: relative;
  width: 100%;
  height: 300px;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/* Alert Messaging */
  .alert-holder {
    // background: $orange;
    // color: white;
    background: #d0f0ef;
    color: #444c7a;
    margin: 0;
    padding: 15px;
    border-radius: 0;
    font-weight: 400;
    text-align: center;
    border-bottom: 2px solid white;

    @media (max-width: 767px) {
      margin: 0 -15px;
    }

    .icon-holder {
      padding: 0 15px;

      @media (max-width: 991px) {
        padding: 0;
      }
    }

    .alert-msg {
      padding: 0 15px;

      @media (max-width: 767px) {
        padding: 0 0 0 15px;
      }

      @media (max-width: 600px) {
        padding: 15px 0 0;
      }

      :last-child {
        margin: 0;
      }

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.5;

        @media (max-width: 767px) {
          font-size: 18.66px;
        }

        a {
          // border-bottom: 2px solid white;
          border-bottom: 2px solid #444c7a;
        }
      }

      a {
        // color: white;
        color: #444c7a;
        font-weight: 700;
      }
    }

    .icon-alert {
      @extend .icon;
      content: '';
      display: inline-block;
      width: 60px;
      height: 50px;
      background-position: 0 -131px;
      float: left;
    }
  }


/* Chat */
@media (max-width: 767px) {
  .chatButtonContainer {
    display: none;
  }
}

#closeChatImage {
  img {
    width: 16px;
  }
}

.modal-backdrop {
  z-index: 9990;
}

/* Galleries */
.bwg_close_btn {
  color: $teal;

  &:hover, &:focus {
    color: $orange;
    opacity: 1;
  }
}

.bwg_thumb_active .bwg_filmstrip_thumbnail_img_wrap {
  // outline: 3px solid $orange !important;
  // outline-offset: -3px;

  outline: auto 5px -webkit-focus-ring-color;
  outline-offset: -5px;
}

#spider_popup_left-ico, #spider_popup_right-ico {
  color: $teal;

  &:hover, &:focus {
    color: $orange !important;
    opacity: 1;
  }

  &:focus {
    border: 1px solid $orange !important;
  }
}
