.chl-block {
  margin: 50px 0;

  @media (max-width: 767px) {
    margin: 25px -15px;
  }

  &.hero {
    margin: 0;

    @media (max-width: 767px) {
      margin: 0 -15px;
    }

    .hero-details {
      position: relative;

      .slide {
        .content {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          background: rgba(66,75,131, 0.8);
          padding: 5px 20px 15px;
          text-align: center;
          color: white;
          margin: 0;
          z-index: 1;

          &.bottom {
            top: auto;
            bottom: 0;

            @media (max-width: 767px) {
              top: calc(50% - 3.6%);
              transform: translateY(-50%);
              padding: 5px 15px 13px;
              bottom: auto;
            }
          }

          @media (max-width: 767px) {
            top: calc(50% - 3.6%);
            transform: translateY(-50%);
            padding: 5px 15px 13px;
          }

          @media (max-width: 374px) {
            padding: 5px 10px;
          }

          h1 {
            margin: 0;
            line-height: 1.05;
          }
        }

        .hero-bg {
          padding-bottom: 35.8%;

          @media (max-width: 767px) {
            padding-bottom: 132%;
          }
        }
      }
    }

    &.full-hero {
      .hero-details {
        .slide {
          .content {
            @media (max-width: 767px) {
              top: 0;
              transform: none;
            }

            &.bottom {
              @media (max-width: 767px) {
                top: auto;
                transform: none;
                bottom: 0;
              }
            }
          }
        }
      }
    }

    &.location-hero {
      .hero-details {
        .slide {
          .content {
            @media (max-width: 767px) {
              top: calc(50% - -1.25%);
            }

            &.bottom {
              @media (max-width: 767px) {
                top: calc(50% - -1.25%);
                bottom: auto;
              }
            }
          }

          .hero-bg {
            padding-bottom: 32.8%;

            @media (max-width: 767px) {
              padding-bottom: 119.25%;
            }
          }
        }
      }
    }

    &.functional-hero {
      .hero-details {
        .slide {
          .content {
            @media (max-width: 767px) {
              top: 50%;
            }
          }

          .hero-bg {
            padding-bottom: 25.85%;

            @media (max-width: 767px) {
              padding-bottom: 101.75%;
            }
          }
        }
      }
    }
  }

  /* Promo */
  &.promo {
    background: $teal;
    margin: 0;
    border-top: 2px solid white;

    @media (max-width: 991px) {
      border-top: 5px solid white;
    }

    @media (max-width: 767px) {
      margin: 0 -15px;
    }

    .inner {
      padding: 20px 0;
      text-align: center;
      color: $blue;

      @media (max-width: 767px) {
        padding: 10px 0;
      }

      header {
        padding: 5px 0;

        @media (max-width: 767px) {
          padding: 0;
        }

        h2 {
          margin-bottom: 0;
          line-height: 1;
          text-transform: uppercase;

          @media (max-width: 767px) {
            line-height: 1.25;
            margin-bottom: 10px;
          }
        }
      }

      .content {
        width: 85%;
        margin: 0 auto;
        line-height: 1.5;

        @media (max-width: 767px) {
          width: 100%;
        }

        p {
          line-height: 1.5;
          margin-bottom: 0;
        }
      }
    }
  }

  /* Header */
  &.solo-header {
    margin: 15px 0;

    h1 {
      margin: 0;
      line-height: 1.05;
    }
  }

  /* Living Vertical */
  &.living-vertical {
    background: $bg-lt-teal;
    border-bottom: 2px solid $teal;
    border-top: 2px solid $teal;
    padding: 10px 0 30px;

    @media (max-width: 767px) {
      padding: 5px 0;
      margin: 30px -15px;
    }

    h2 {
      margin-bottom: 0 !important;
      text-align: center;

      @media (max-width: 991px) {
        margin-bottom: -10px;
      }
    }

    h3 {
      margin-bottom: 5px;

      @media (max-width: 991px) {
        font-size: 22px;
      }
    }

    .living-options {
      padding-top: 20px;

      @media (max-width: 767px) {
        padding-top: 10px;
      }
    }

    .living-option {
      // border-top: 3px solid white;
      border: none !important;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: calc(100% - 30px);
        height: 3px;
        background: white;
        margin: 0 auto;
      }

      &:nth-child(1) {
        &:before {
          display: none;
        }
      }

      @media (max-width: 767px) {
        padding-top: 20px;
        border: 1px solid white !important;

        &:nth-child(1) {
          padding-top: 0;
        }

        &:before {
          display: none;
        }
      }

      header {
        position: static;

        @media (max-width: 767px) {
          position: absolute;
          height: 31px;
          top: -31px;
          width: calc(100% - 30px);
          text-align: center;
          background: rgba(66,75,131, 0.85);
          padding: 7px 5px;
        }

        h3 {
          @media (max-width: 767px) {
            color: white;
            margin: 0;
            font-size: 18.66px;
            line-height: 1;
          }
        }
      }

      .living-details {
        padding-top: 30px;

        @media (max-width: 991px) {
          padding: 0;
        }

        @media (max-width: 767px) {
          padding: 10px 15px;
        }

        p {
          padding-right: 30px;
          color: $fontcolor;
          @extend .font-reg;
          letter-spacing: 0;

          @media (max-width: 767px) {
            margin-bottom: 5px;
            padding-right: 0;
          }
        }

        a {
          font-weight: 700;
          @extend .link-orange;
          color: $link-bg-orange;

          @media (max-width: 991px) {
            margin: 5px 0;
          }
        }
      }
    }
  }

  /* Living Horizontal */
  &.living-horizontal {
    background: $bg-gray;
    padding: 0;
    border-bottom: 2px solid $blue;

    @media (max-width: 991px) {
      border-top: 2px solid $blue;
    }

    @media (max-width: 767px) {
      margin: 30px -15px;
    }

    .col-12 {
      padding: 0;
    }

    h2 {
      margin: 0;
      text-align: center;

      @media (max-width: 767px) {
        padding: 15px 0;
      }
    }

    .living-options {
      border-top: 2px solid white;

      @media (max-width: 767px) {
        border: none;
        padding: 0 15px;
      }

      &.two-options {
        .living-option {
          .overlay-header {
            .option-image {
              width: 100%;
              padding-bottom: 52%;
              background-size: cover;
            }
          }
        }
      }

      .living-option {
        &:nth-child(2) {
          border-left: 2px solid white;
          border-right: 2px solid white;

          @media (max-width: 767px) {
            border: none;
          }
        }

        .overlay-header {
          position: relative;

          .option-image {
            width: 100%;
            padding-bottom: 52%;
            background-size: cover;
          }

          h3 {
            position: absolute;
            bottom: 0;
            left: 0;
            background: rgba(36,45,101, 0.75);
            width: 100%;
            color: white;
            margin: 0;
            padding: 5px 10.5%;
            text-transform: uppercase;
            @extend .font-reg;
            font-size: 20px;
            text-shadow: 1px 1px 8px rgba(0,0,0, 0.75);

            &:hover, &:active {
              background: rgba(36,45,101, 0.9);
            }

            a {
              color: white;
              display: block;
            }

            @media (max-width: 991px) {
              font-size: 1.1em;
              padding: 5px 8%;
            }

            @media (max-width: 767px) {
              text-align: center;
              font-size: 18px;
              padding: 8px 15px 12px;
            }
          }
        }

        p {
          padding: 25px 10.5%;
          margin-bottom: 0;

          @media (max-width: 991px) {
            padding: 20px 8%;
          }

          @media (max-width: 767px) {
            padding: 20px 0;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }

  /* Colored BG */
  &.color-bg {
    margin: 0;

    @media (max-width: 767px) {
      margin: 0 -15px;
    }

    .color-container {
      padding: 0;
    }

    .inner {
      padding: 0;
      background: $bg-lt-teal;

      h2 {
        padding: 0 15px;
        background: $blue;
        color: white;
        text-align: center;
      }

      .content {
        font-weight: 400;
        padding: 5px 15px 15px;

        h5 {
          font-weight: 400;
        }

        @media (max-width: 767px) {
          font-weight: 300;

          h5 {
            font-weight: 300;
          }
        }
      }
    }
  }

  /* Featured Left */
  &.featured-left {

    @media (max-width: 767px) {
      margin: 30px -15px;
    }

    img {
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    .content {
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  /* Featured Horizontal */
  &.featured-horizontal {
    .featured-img {
      width: 100%;
      padding-bottom: 24.2%;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 30px 0 0;

      @media (max-width: 767px) {
        padding-bottom: 99.5%;
        margin: 25px 0;
      }
    }

    .content {
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  /* Img Left */
  &.img-left {

    @media (max-width: 767px) {
      margin: 30px -15px;
    }

    img {
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    .content {
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  /* Img Right */
  &.img-right {

    @media (max-width: 767px) {
      margin: 30px -15px;
    }

    img {
      @media (max-width: 767px) {
        margin-top: 15px;
      }
    }

    .content {
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  /* Img Right w/Lists */
  &.img-right-list {
    margin: 35px 0;

    @media (max-width: 767px) {
      margin: 25px -15px;
    }

    img {
      margin-top: 5px;

      @media (max-width: 767px) {
        margin-top: 15px;
      }
    }

    .list-holder {
      margin-top: 20px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }

      ul {
        margin-bottom: 0;
      }
    }
  }

  &.three-column {

    @media (max-width: 767px) {
      margin: 25px -15px;
    }

    .intro {
      margin-bottom: 25px;
    }

    .col-md {
      @media (max-width: 767px) {
        margin: 15px 0;

        ul {
          margin: 0;
        }

        &:first-child() {
          margin-top: 0;
        }
      }
    }
  }

  &.page-intro {
    margin: 35px 0 35px;

    @media (max-width: 767px) {
      margin: 25px -15px;
    }

    .intro {
      :last-child(){
        margin-bottom: 0;
      }
    }
  }

  &.list {

    .intro {
      margin-bottom: 25px;
    }

    // @media (max-width: 767px) {
    //   margin: 30px -15px;
    // }
  }

  &.full-column {

    // @media (max-width: 767px) {
    //   margin: 30px -15px;
    // }
  }

  &.happenings {
    h2 {
      text-align: center;
    }

    .happening {
      &:hover, &:focus {
        .details {
          .title {
            color: $dk-orange;
            font-weight: 400;
          }
        }
      }

      .details {
        margin: 8px 0;

        @media (max-width: 767px) {
          margin-bottom: 15px;
        }

        p {
          line-height: 1.5;

          @media (max-width: 991px) {
            font-size: 17px;
            line-height: 1.25;
          }

          @media (max-width: 767px) {
            font-size: 17px;
          }

          &.cat {
            @extend .font-reg;
            font-size: 16px;
            line-height: 1.25;
            text-transform: uppercase;
            margin-bottom: 0;
            color: $blue;

            @media (max-width: 767px) {
              letter-spacing: 1px;
            }
          }

          &.title {
            color: $blue;
          }
        }
      }
    }

    .btn {
      margin: 15px auto;
    }
  }

  &.juicer {
    margin: 30px 0;

    @media (max-width: 767px) {
      padding: 30px 0;
    }

    h2 {
      text-align: center;
    }
  }

  &.button {
    margin: 0;

    .btn {
      margin: 0;
    }
  }

  &.back-link {
    margin: 10px 0;

    @media (max-width: 767px) {
      > .col-12 {
        padding: 0;
      }
    }

    a {
      font-weight: 400;
      color: $link-orange;

      &:hover, &:focus {
        color: $link-orange-hover;
      }
    }
  }

  &.philosophies {
    background: $bg-form;
    margin: 0;

    @media (max-width: 767px) {
      margin: 0 -15px 40px;
    }

    &.no-margin-top {
      margin-top: -35px;

      @media (max-width: 767px) {
        margin-top: -20px;
      }
    }

    .philosophies-header {
      text-align: center;
      padding: 25px 0;

      h3 {
        margin: 0;
        color: $blue;
        font-size: 1.4em;
        @extend .font-reg;
        position: relative;
        text-transform: none;

        @media (max-width: 767px) {
          padding: 15px 0;
        }
      }

      .btn {
        margin-bottom: 15px;
      }
    }
  }

  &.block-video {
    @media (max-width: 767px) {
      margin: 25px -15px 20px -15px;
    }

    &.bg-color {
      margin: 0;
      padding: 50px 15px;
      background: #eff9f9;

      @media (max-width: 767px) {
        padding: 30px 0 25px;
        margin: 0 -15px;
      }
    }

    .video-copy {
      :last-child {
        margin-bottom: 0;
      }
    }

    .video_thumbnail {
      position: relative;

      &.video-left {
        @media (max-width: 767px) {
          margin-top: 0;
          margin-bottom: 15px;
        }
      }

      @media (max-width: 767px) {
        margin-top: 10px;
      }

      .play_btn {
        display: block;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-image: url(img/btn_play.png);
        // background-repeat: no-repeat;
        // background-size: 125px;
        // background-position: 50%;
        z-index: 6;
      }
    }

    #videoModal {
      z-index: 100000;
      .modal-body {
        position:relative;
        padding:0px;
      }
      .close {
        position:absolute;
        right: -15px;
        top:-45px;
        z-index:999;
        font-size:2rem;
        font-weight: normal;
        color:#fff;
        opacity:1;
        padding: 5px 15px;
        text-shadow: 2px 2px 4px rgba(0,0,0,.75);
      }
      .modal-dialog {
        max-width: 800px;
        margin: 80px auto 30px;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }

      @media (max-width: 767px) {
        padding: 15px !important;
        // padding-right: 50px!important;
      }
    }
  }

}

/* Overrides */
.amenities {
  .chl-block {
    &.three-column {
      .col-md {
        @media (max-width: 767px) {
          margin: 0;

          ul {
            margin: 0 0 25px;
          }
        }
      }
    }
  }

}
