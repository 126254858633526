.communities {
  &.community-reviews {
    //hero
    .hero {
      margin: 0;

      @media (max-width: 767px) {
        margin: 0 -15px;
      }

      .hero-details {
        position: relative;

        .slide {
          .content {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            background: rgba(66,75,131, 0.8);
            padding: 5px 20px 15px;
            text-align: center;
            color: white;
            margin: 0;
            z-index: 1;

            &.bottom {
              top: auto;
              bottom: 0;

              @media (max-width: 767px) {
                top: calc(50% - 3.6%);
                transform: translateY(-50%);
                padding: 5px 15px 13px;
                bottom: auto;
              }
            }

            @media (max-width: 767px) {
              top: calc(50% - 3.6%);
              transform: translateY(-50%);
              padding: 5px 15px 13px;
            }

            @media (max-width: 374px) {
              padding: 5px 10px;
            }

            h1 {
              margin: 0;
              line-height: 1.05;
            }
          }

          .hero-bg {
            padding-bottom: 35.8%;

            @media (max-width: 767px) {
              padding-bottom: 132%;
            }
          }
        }
      }

      &.full-hero {
        .hero-details {
          .slide {
            .content {
              @media (max-width: 767px) {
                top: 0;
                transform: none;
              }

              &.bottom {
                @media (max-width: 767px) {
                  top: auto;
                  transform: none;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .reviews {
      background-color: $bg-lt-teal;
      padding: 50px 100px;

      @media (max-width: 1199px) {
        padding: 25px 50px;
      }

      @media (max-width: 991px) {
        padding: 25px 15px;
      }

      @media (max-width: 767px) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    //reviews
    .reviews-wrapper {
      position: relative;
      width: 100%;
      height: 670px;

      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        border: none;
      }

      &.reviews-form {
        max-height: 670px;
      }

      &.reviews-display {
        #v-review-display-widget-container {
          width: 100% !important;
          background: $bg-lt-teal !important;

          // @media (max-width: 767px) {
          //   max-width: 320px !important;
          //   margin: 0 auto;
          // }
        }

        .v-feed {
          ul {
            li {
              &:before {
                content: '';
                display: none;
              }
            }
          }

          .v-feed-item {
            font-size: 16px !important;
          }

          .v-feed-item-excerpt {
            font-size: 18px !important;
          }

          .v-feed-item-date a {
            font-size: 16px !important;
            color: $gray !important;
          }

          .star-icon {
            color: $blue;
          }

          .view-all-comments {
            a {
              color: black !important;
            }
          }
        }
      }
    }

  }
}
