//colors
$orange: #fc4c07;
$dk-orange: #c53902;
$dkst-orange: #D54407;
$location-nav-orange: #C13E06;
$blue: #424b83;
$blue-hover: #323860;
$active-blue: #66769E;
$gray: #414042;
$teal: #65c8c6;
$lt-orange: #f89829;
$fontcolor: $gray;

/* Backgrounds */
$bg-gray: #ecedf2;
$bg-form: #d0f0ef;
$bg-lt-orange: #fef4e9;
$bg-lt-teal: #eff9f9;

/* link colors */
$link-orange: $orange;
$link-orange-hover: #e44907;
$link-bg-orange: #e44907;
$link-bg-orange-hover: #c24907;

/* Misc */
$spritesize: 500px 500px;
