/*!
Theme Name: Heartis
Theme URI: https://heartis.com
Author: Jason Mallott & Han Phan
Author URI: https://clickherelabs.com
Description: Custom genesis child theme for Heartis.
Version: 1.0
License: GNU General Public License v3 or later
License URI: http://www.gnu.org/licenses/gpl-3.0.html
Tags:
Text Domain: custom
*/

$icon-font-path: "fonts/";
// $fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts/";

@import url("https://use.typekit.net/dev1bof.css");

// @import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/featherlight/src/featherlight";
@import "../../node_modules/featherlight/src/featherlight.gallery";
@import "vars";
@import "global";
@import "print";
@import "header";
@import "home";
// @import "sections";
@import "communities";
@import "community-single";
@import "community-dining";
@import "community-schedule";
@import "community-events";
@import "community-reviews";
@import "locations";
@import "contact";
@import "blog-list";
@import "blog-single";
@import "forms";
@import "careers";
@import "blocks";
@import "footer";
@import "subscription";
