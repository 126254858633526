.locations {
  .locations-search {
    background: $bg-gray;
    margin: 0;
    padding: 15px 0 0;
    border-bottom: 2px solid $blue;

    @media (max-width: 991px) {
      margin: 0 -15px;
    }

    header {
      margin-bottom: 15px;

      h2 {
        font-size: 41px;
        text-align: center;

        @media (max-width: 767px) {
          font-size: 33px;
          line-height: 1.25;
        }
      }
    }

    .lifestyle-filter {
      form {
        @media (max-width: 767px) {
          width: 60%;
          max-width: 300px;
          margin: 20px auto 5px;
        }
      }

      input[type="text"] {
        width: 100%;
        max-width: 300px;
        height: 34px;
        line-height: 34px;
        font-size: 18px !important;
        padding: 5px 10px;
        font-weight: 300;
        margin-bottom: 25px;

        @media (max-width: 767px) {
          padding: 6px 5px;
        }
      }

      label {
        @extend .font-reg;
        font-size: 20px;
        margin-bottom: 5px;
      }

      .form-check {
        margin-top: 5px;

        input[type="checkbox"] {
          margin-top: 8px;
        }

        label {
          margin-bottom: 0;
        }
      }

      @media (max-width: 767px) {
        .btn {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .location-map {
      padding-right: 0;

      @media (max-width: 991px) {
        padding: 0;
      }

      .map-legend {
        width: 100%;
        padding: 7px 15px;
        @extend .clearfix;
        background: white;

        .legends {
          display: table-cell;
          position: relative;
          left: 50%;

          @media (max-width: 767px) {
            display: block;
            width: 320px;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 374px) {
              position: static;
              transform: none;
            }
          }
        }

        p {
          float: left;
          line-height: 1.35;
          margin-bottom: 0;
          font-size: 17px;

          @media (max-width: 374px) {
            font-size: 15px;
            line-height: 1.5;
          }

          &.coming-soon {
            margin-left: 15px;

            &:before {
              background-position: 0 -31px;
            }
          }

          &:before {
            content: '';
            @extend .icon;
            width: 17px;
            height: 25px;
            margin-right: 10px;
            margin-bottom: -5px;
            background-position: -28px -31px;

            @media (max-width: 767px) {
              margin: -3px 5px 0 0;
              vertical-align: middle;
              top: auto;
            }
          }
        }
      }
    }

    .map-wrapper {
      border-top: 2px solid white;
      border-left: 2px solid white;
      padding-bottom: 44%;

      @media (max-width: 991px) {
        padding-bottom: 60%;
      }

      @media (max-width: 767px) {
        padding-bottom: 100%;
        border-bottom: 2px solid white;
        border-left: 0;
      }
    }
  }

  .locations-list {
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    .location-item {
      padding: 30px 15px;

      &:nth-child(6n+4), &:nth-child(6n+5), &:nth-child(6n+6) {
        background: $bg-lt-teal;
      }

      @media (max-width: 991px) {
        &:nth-child(6n+4), &:nth-child(6n+5), &:nth-child(6n+6) {
          background: transparent;
        }

        &:nth-child(4n+3), &:nth-child(4n+4) {
          background: $bg-lt-teal;
        }
      }

      @media (max-width: 767px) {
        &:nth-child(even) {
          background: $bg-lt-teal;
        }

        &:nth-child(odd) {
          background: transparent;
        }
      }

      .location-img {
        width: 100%;
        margin: 0 auto;
        position: relative;

        span {
          position: absolute;
          right: 0;
          bottom: 5px;
          z-index: 1;
        }

        @media (max-width: 767px) {
          .round-img {
            margin-bottom: 10px;
          }
        }

        img {
          border-radius: 50%;
        }
      }

      .location-details {
        width: 85%;
        margin: 20px auto 0;
        padding: 0 15px 0 25px;
        @extend .font-reg;

        @media (max-width: 1199px) {
          width: 100%;
        }

        @media (max-width: 991px) {
          width: 85%;
          margin: 20px auto 0;
        }

        @media (max-width: 767px) {
          width: 70%;
          margin: 20px auto 0;
          padding: 0;
        }
      }

      p {
        line-height: 1.5;

        a {
          font-weight: 400;
          border: none;
        }
      }

      .location-name {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 0;

        a {
          color: $link-bg-orange;
          font-weight: 700;

          &:hover, &:focus {
            color: $dk-orange;
          }
        }

        @media (max-width: 767px) {
          font-size: 18.66px;
          margin-bottom: 10px;
          text-align: center;
        }
      }

      .address {
        position: relative;

        &:before {
          content: '';
          @extend .icon;
          width: 20px;
          height: 26px;
          background-position: -145px -62px;
          position: absolute;
          top: 7px;
          left: -25px;
        }

        a {
          color: $fontcolor;

          @media (max-width: 767px) {
            font-size: 17px;
          }

          &:hover, &:focus {
            color: $dk-orange;
          }
        }
      }

      .phone {
        position: relative;

        &:before {
          content: '';
          @extend .icon;
          width: 20px;
          height: 32px;
          background-position: -54px -64px;
          position: absolute;
          top: 1px;
          left: -25px;
        }

        a {
          color: $fontcolor;

          @media (max-width: 767px) {
            font-size: 17px;
          }

          &:hover, &:focus {
            color: $dk-orange;
          }
        }
      }

      .lifestyle {
        p {
          position: relative;
          margin-bottom: 0;

          @media (max-width: 767px) {
            font-size: 17px;
          }

          &:before {
            content: '';
            @extend .icon;
            width: 20px;
            height: 32px;
            background-position: -101px -101px;
            position: absolute;
            top: 8px;
            left: -25px;
          }
        }
      }

    }
  }
}
