.community-single {
  section {
    margin: 50px 0;
    padding: 0;

    h2 {
      text-align: center;
    }

    /* Testimonials */
    &.hero-video {
      margin: 0;

      .content {
        background: $blue;
        padding: 5px 20px 15px;
        text-align: center;
        color: white;
        margin: 0;
        z-index: 1;

        h1 {
          margin: 0;
          line-height: 1.05;
          color: white;
        }
      }

      @media (max-width: 767px) {
        margin: 0 -15px 0;
      }
    }

    /* Promo */
    &.promo {
      background: $teal;
      margin-top: 0;
      border-top: 2px solid white;
      padding: 15px 0;

      @media (max-width: 767px) {
        margin: 0 -15px;
        border-top: 5px solid white;
      }

      .inner {
        padding: 0 0 15px;
        text-align: center;
        color: $blue;

        @media (max-width: 767px) {
          padding: 0 15px;
        }

        header {
          padding: 5px 0;

          @media (max-width: 767px) {
            padding: 0;
          }

          h2 {
            margin-bottom: 0;
            line-height: 1;
            text-transform: uppercase;

            @media (max-width: 767px) {
              line-height: 1.25;
              margin-bottom: 10px;
            }
          }
        }

        .content {
          width: 85%;
          margin: 0 auto;
          line-height: 1.5;

          @media (max-width: 991px) {
            width: 100%;
            padding: 0 15px;
          }

          @media (max-width: 767px) {
            width: 100%;
            text-align: left;
            padding: 0;
          }

          p {
            line-height: 1.5;
            font-weight: 400;
          }
        }
      }
    }

    /* Quick Form */
    &.quick-form {
      @media (max-width: 767px) {
        margin: 20px -15px 30px;
      }

      /* About */
      .about-ctas {
        margin-top: 40px;

        @media (max-width: 767px) {
          margin: 20px -15px;
        }

        .about-cta {
          p {
            @extend .link-orange;
            font-size: 18.66px;
            line-height: 1.5;
            margin-top: 10px;
            font-weight: bold;

            @media (max-width: 767px) {
              margin: 5px 0 15px;

              span, br {
                display: none;
              }
            }
          }

          &:hover {
            p {
              color: $link-bg-orange-hover;
            }
          }
        }
      }

      /* Contact */
      .quick-contact {
        .inner {
          padding: 20px 8%;
          background: $bg-form;

          @media (max-width: 991px) {
            padding: 25px 15px;
          }
        }

        .gform_confirmation_wrapper {
          .reset-link {
            margin: 10px 0 0;
          }

          a {
            @extend .inline-link;
          }
        }

        h3 {
          text-align: center;
          font-weight: 300;
          text-transform: none;
          color: $fontcolor;

          @media (max-width: 767px) {
            font-size: 35px;
          }

          @media (max-width: 374px) {
            font-size: 26px;
          }
        }

        p {
          @media (max-width: 991px) {
            font-size: 20px;
          }
        }

        .required-text {
          p {
            font-size: 16px;

            @media (max-width: 991px) {
              font-size: 14px;
            }
          }
        }

        .footnote {
          font-size: .8em;
          line-height: 1.25;
          margin-bottom: 0;
          // text-align: center;
          font-style: italic;
          margin-top: 5px;
        }
      }
    }

    /* Living */
    &.living {
      background: $bg-gray;
      padding: 0;
      margin-bottom: 5px;
      border-bottom: 2px solid $blue;

      @media (max-width: 767px) {
        margin: 15px -15px;
        border-top: 2px solid $blue;
      }

      .col-12 {
        padding: 0;
      }

      h2 {
        margin: 0;

        @media (max-width: 767px) {
          padding: 15px 0;
        }
      }

      .living-options {
        border-top: 2px solid white;

        @media (max-width: 767px) {
          border: none;
          padding: 0 15px;
        }

        &.two-options {
          .living-option {
            .overlay-header {
              .option-image {
                width: 100%;
                padding-bottom: 52%;
                background-size: cover;
              }
            }
          }
        }

        .living-option {
          &:nth-child(2) {
            border-left: 2px solid white;
            border-right: 2px solid white;

            @media (max-width: 767px) {
              border: none;
            }
          }

          .overlay-header {
            position: relative;

            .option-image {
              width: 100%;
              padding-bottom: 52%;
              background-size: cover;
            }

            h3 {
              position: absolute;
              bottom: 0;
              left: 0;
              background: rgba(36,45,101, 0.75);
              width: 100%;
              color: white;
              margin: 0;
              padding: 5px 10.5%;
              text-transform: uppercase;
              @extend .font-reg;
              font-size: 20px;
              text-shadow: 1px 1px 8px rgba(0,0,0, 0.75);

              @media (max-width: 991px) {
                font-size: 1.1em;
                padding: 5px 8%;
              }

              @media (max-width: 767px) {
                text-align: center;
                font-size: 18px;
                padding: 8px 15px 12px;
              }
            }
          }

          a {
            display: block;
          }

          p {
            padding: 25px 10.5%;
            margin-bottom: 0;

            @media (max-width: 991px) {
              padding: 25px 8% 15px;
            }

            @media (max-width: 767px) {
              padding: 20px 0;
              text-align: left;
              font-weight: 400;
            }
          }
        }
      }
    }

    /* Guidebook */
    &.guidebook {
      margin-top: -5px;
      background: #c6c9da;

      @media (max-width: 767px) {
        margin: -15px -15px 35px;
      }

      .guidebook-header {
        text-align: center;
        padding: 25px 0;

        h3 {
          margin: 0;
          color: $blue;
          font-size: 32px;
          text-transform: none;
          @extend .font-reg;
          position: relative;

          @media (max-width: 991px) {
            font-size: 26px;
          }

          @media (max-width: 767px) {
            font-size: 24px;
            padding: 15px 0;
          }
        }

        .guidebook-toggle {
          margin: 15px 0 0;

          &.open {
            display: none;
          }
        }

        .guidebook-close {
          display: none;
          color: $blue;
          font-size: 24px;
          @extend .font-reg;
          position: absolute;
          top: 0;
          right: 15px;
          text-decoration: none;

          @media (max-width: 767px) {
            top: 5px;
            right: 5px;
            padding: 10px;
          }

          &.open {
            display: block;
          }
        }
      }

      .guidebook-details {
        display: none;
        margin-bottom: 25px;

        p {
          font-size: 1.35em;

          @media (max-width: 1199px) {
            font-size: 21px;
          }

          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 24px;
            padding: 0 5px;
            text-align: center;
          }

          &.accent {
            margin-top: 30px;
            color: $blue;
            @extend .font-reg;
          }
        }

        .guidebook-inner {
          background: white;
          padding: 20px 15px;
        }

        .guidebook-form {

          .gform_wrapper {
            ul {
              margin: 0;
            }

            .validation_error {
              margin: 15px 0 25px;
            }

            .gform_footer {
              text-align: center;
              margin-top: 10px;

              input[type="submit"] {
                margin: 0 auto 0;
              }
            }
          }

          .inner {
            padding: 10px 10% 20px;
            background: $bg-form;

            @media (max-width: 991px) {
              padding: 10px 20px 20px;
            }

            @media (max-width: 767px) {
              padding: 25px 10%;
            }

            .guidebook-checkbox {
              .gfield_label {
                display: block !important;
                position: static;
                width: 100%;
                height: auto;
                padding: 0;
                overflow: visible;
                clip: none;
                line-height: 1.5;
                margin-bottom: 0;

                @media (max-width: 991px) {
                  font-size: 16px;
                }

                @media (max-width: 767px) {
                  margin: 20px 0 20px;
                }
              }
            }

            .gfield_checkbox {
              margin: 10px 0 20px;

              label {
                // line-height: 1;
                line-height: 1.5;
                margin-bottom: 5px;

                @media (max-width: 767px) {
                  margin-bottom: 15px;
                }
              }
            }

            .guidebook-confirmation {
              padding: 25px 0;
              font-size: 1em;
              text-align: center;
              color: $blue;
              @extend .font-reg;

              .email-icon {
                width: 100%;
                max-width: 156px;
                margin: 20px auto 40px 13%;
                padding-bottom: 23%;
                display: block;
                background: url(img/icon-email.png) no-repeat 0 0;
                background-size: cover;

                @media (max-width: 991px) {
                  width: 75%;
                  padding-bottom: 30%;
                  margin: 10px auto 30px 5%;
                  max-width: none;
                }

                @media (max-width: 767px) {
                  width: 60%;
                  padding-bottom: 25%;
                  max-width: none;
                  margin: 10px auto 30px 8%;
                }
              }
            }
          }
        }
      }

      .gform_wrapper {
        ul {
          list-style-type: none;
          padding: 0;
          margin: .9em 0 0;

          li.gfield input {
            color: $fontcolor;
            font-weight: 400;

            @media (max-width: 991px) {
              font-size: 16px !important;
            }
          }

          .gfield {
            margin: 15px 0 0 !important;

            .gfield_description {
              line-height: 1.25;
              font-size: 20px;
              font-weight: 400;

              @media (max-width: 991px) {
                font-size: 17px;
              }

              @media (max-width: 767px) {
                font-size: 16px;
                line-height: 1.5;
              }
            }

            .gfield_checkbox {
              li {
                vertical-align: middle;
              }

              input {
                width: auto;
                display: inline-block;
                margin-top: 0 !important;
              }

              label {
                display: inline-block;
              }
            }

            .gfield_checkbox {
              li {
                position: relative;
                padding-left: 30px !important;

                label {
                  font-weight: 400;

                  &:after {
                    content: "";
                    background: white;
                    width: 20px;
                    height: 20px;
                    display: block;
                    position: absolute;
                    border: 1px solid $fontcolor;
                    left: 0;
                    top: 4px;
                    border-radius: 0;
                    z-index: 0;

                    @media (max-width: 1199px) {
                      top: 4px;
                    }

                    @media (max-width: 991px) {
                      top: 4px;
                    }

                    @media (max-width: 767px) {
                      top: 2px;
                    }
                  }

                  &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 15px;
                    border: solid $blue;
                    border-width: 0 3px 3px 0;
                    left: 6px;
                    top: 6px;
                    transform: rotate(45deg);
                    opacity: 0;
                    z-index: 1;
                    transition: all 100ms;
                  }
                }
              }
            }

            input[type="checkbox"] {
              opacity: 0;
              outline: none;
              z-index: 100;
              width: 27px;
              height: 27px;
              top: 0;
              left: 0;
              position: absolute;
              appearance: none;
              cursor: pointer;

              @media (max-width: 991px) {
                width: 24px;
                height: 24px;
              }

              + label {
                cursor: pointer;
              }

              &:focus + label {
                &:after {
                  outline: rgb(0, 95, 204) auto 5px;
                  border-radius: 0;
                }
              }

              &:checked + label {
                &:before {
                  opacity: 1;
                  top: 6px;

                  @media (max-width: 991px) {
                    top: 3px;
                  }
                }
              }
            }

            input {
              width: 100%;
              padding: 3px 5px;

              @media (max-width: 767px) {
                padding: 6px 5px;
              }
            }

            &.gform_validation_container {
              display: none;
            }

            .gfield_label {
              @extend .sr-only;
            }
          }
        }

        .gform_footer {
          text-align: center;
          margin-top: 10px;

          input[type="submit"] {
            margin: 0 auto 20px;
          }
        }
      }

    }

    /* Philosophies */
    &.philosophies {
      margin-top: -5px;
      background: #c6c9da;

      @media (max-width: 767px) {
        margin: -15px -15px 35px;
      }

      &.no-margin-top {
        margin-top: -5px;

        @media (max-width: 767px) {
          margin-top: -20px;
        }
      }

      .philosophies-header {
        text-align: center;
        padding: 25px 0;

        h3 {
          margin: 0;
          color: $blue;
          font-size: 1.4em;
          @extend .font-reg;
          position: relative;
          text-transform: none;

          @media (max-width: 767px) {
            padding: 15px 0;
          }
        }

        .btn {
          margin-bottom: 15px;

          @media (max-width: 991px) {
            margin-bottom: 0;
          }
        }
      }
    }

    /* Testimonials */
    &.testimonials {
      margin-top: 0;
      border-bottom: 2px solid $blue;
      padding-bottom: 50px;

      @media (max-width: 767px) {
        padding-bottom: 25px;
      }

      .testimonials-header {
        text-align: center;
        padding: 25px 0;

        @media (max-width: 767px) {
          padding: 15px 0;
        }

        h2 {
          margin: 0;

          @media (max-width: 767px) {
            padding: 15px 0 0;
          }
        }
      }

      .testimonial-images {
        img {
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        :last-child() {
          img {
            @media (max-width: 767px) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    /* Partnerships */
    &.partnerships {
      margin: 50px 0;

      @media (max-width: 991px) {
        margin: 30px -15px 20px;
      }

      @media (max-width: 767px) {
        margin: 30px -15px 0;
      }

      .partnership-item {
        margin-bottom: 30px;

        @media (max-width: 767px) {
          margin-bottom: 15px;
        }

        &:last-child(){
          margin-bottom: 0;
        }

        h2 {
          @media (max-width: 767px) {
            line-height: 1.15;
          }
        }
      }

      img {
        @media (max-width: 767px) {
          margin-bottom: 15px;
        }
      }
    }

    /* Happenings */
    &.happenings {
      padding: 5px 0 0;
      margin: 0 0 15px;

      @media (max-width: 767px) {
        margin: 15px -15px 0;
        padding: 0 0 5px;
      }

      .happening {
        &:hover, &:focus {
          .details {
            .title {
              color: $dk-orange;
              font-weight: 400;
            }
          }
        }

        .details {
          margin: 8px 0;

          @media (max-width: 767px) {
            margin-bottom: 15px;
          }

          p {
            line-height: 1.5;

            @media (max-width: 991px) {
              font-size: 17px;
              line-height: 1.25;
            }

            @media (max-width: 767px) {
              font-size: 17px;
            }

            &.cat {
              @extend .font-reg;
              font-size: 16px;
              line-height: 1.25;
              text-transform: uppercase;
              margin-bottom: 0;
              color: $blue;

              @media (max-width: 767px) {
                letter-spacing: 1px;
              }
            }

            &.title {
              color: $blue;
            }
          }
        }
      }

      .btn {
        margin: 15px auto;
      }
    }

    /* Jucier */
    &.juicer {
      margin: 30px 0 50px;

      @media (max-width: 991px) {
        margin: 35px -15px 50px;
      }

      @media (max-width: 767px) {
        margin: 0 -15px 30px;
      }
    }

  }
}
