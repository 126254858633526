.communities {

  &.opening-soon {
    section {
      &.hero {
        .slide {
          .content {
            padding: 2px 20px 5px;

            @media (max-width: 767px) {
              padding: 5px 15px 5px;
              top: auto;
              bottom: 0;
              transform: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 141px;
  }

  /* Community Nav */
  .community-nav-holder {
    padding: 0 0 0 15px;
    position: fixed;
    background: $blue;
    margin: 0;
    top: 145px;
    width: calc(100% - 30px);
    max-width: 1200px;
    z-index: 1000;
    line-height: 52px;
    border-bottom: 2px solid white;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -o-transition: all .25s ease;
    -ms-transition: all .25s ease;
    transition: all .25s ease;

    &.sticky {
      top: 100px;
    }

    @media (max-width: 767px) {
      margin: 0 -15px;
      line-height: 36px;
      border-bottom: 5px solid white;
    }

    @media (max-width: 767px) {
      position: fixed;
      top: 100px;
      z-index: 10;
      width: 100%;
      line-height: 36px;
      border-bottom: 5px solid white;
    }

    .brand {
      float: left;

      p {
        position: relative;
        font-size: 21px;
        line-height: 52px;
        margin: 0;
        color: white;
        @extend .font-reg;
        text-transform: uppercase;

        a {
          color: white;
          border: none;
        }

        @media (max-width: 991px) {
          font-size: 18px;
          letter-spacing: .05em;
          line-height: 34px;
        }

        &:before {
          position: relative;
          margin-right: 10px;
          left: 0;
          top: 6px;
          width: 18px;
          height: 26px;
          content: '';
          @extend .icon;
          background-position: -144px -62px;
        }
      }
    }

    .community-nav {
      float: right;
      text-align: right;

      nav {
        @media (max-width: 991px) {
          display: none;
        }
      }

      a {
        float: left;
        text-transform: uppercase;
        padding: 0 10px;
        color: white;
        font-size: 15px;
        font-weight: 400;
        line-height: 52px;
        @extend .font-reg;
        letter-spacing: .05em;

        &:hover, &:focus, &.current-menu-item {
          background: $active-blue;
        }

        &.schedule {
          display: inline-block;
          margin: 0;
          background: $dkst-orange;
          padding: 0 15px;

          &:hover, &:focus {
            background: $link-orange-hover;
          }
        }

        &.explore-toggle {
          display: none;
          background: $teal;
          color: $blue;
          font-weight: 700;
          font-size: 18.66px;

          &:after {
            position: relative;
            content: '';
            @extend .icon;
            height: 10px;
            width: 15px;
            display: inline-block;
            margin-left: 5px;
            top: -1px;
            background-position: -1px -102px;
            border: 0;
            vertical-align: inherit;
          }

          &.open {
            &:after {
              background-position: -26px -102px;
            }
          }

          @media (max-width: 991px) {
            display: block;
            padding: 0 20px;
            line-height: 36px;
          }
        }
      }
    }

    .mobile-community-nav {
      display: none;
      padding: 15px 15px 30px;
      border-bottom: 2px solid $orange;
      background: white;
      position: absolute;
      top: 41px;
      width: 100%;
      z-index: 20;

      a {
        display: block;
        text-align: right;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
        @extend .font-reg;

        &.current-menu-item {
          color: $orange;
        }

        &.schedule {
          display: inline-block;
          background: $dk-orange;
          padding: 3px 20px;
          color: white;
          margin-top: 10px;
          float: right;

          &:hover, &:focus {
            background: $link-orange-hover;
          }
        }
      }

      @media (max-width: 991px) {
        top: 38px;
        left: 0;
      }

      @media (max-width: 767px) {
        top: 41px;
        left: 0;
      }
    }
  }

  /* Global Community page styles */
  section {
    // padding: 25px 15px;

    /* Hero */
    &.hero {
      width: 100%;
      position: relative;
      padding: 0;
      margin: 0;

      @media (max-width: 767px) {
        width: auto;
        margin-left: -15px;
        margin-right: -15px;
      }

      .slide {
        .content {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          background: rgba(66,75,131, 0.8);
          padding: 5px 20px 15px;
          text-align: center;
          color: white;
          margin: 0;
          z-index: 1;

          h1 {
            margin: 0;
            line-height: 1.05;
          }

          @media (max-width: 767px) {
            top: calc(50% - 8.75%);
            transform: translateY(-50%);
            padding: 5px 15px 13px;
            bottom: auto;
          }

          @media (max-width: 374px) {
            padding: 10px;
          }
        }

        .hero-bg {
          padding-bottom: 32.85%;

          @media (max-width: 767px) {
            padding-bottom: 118%;
          }
        }
      }
    }

    &.living-options-holder {
      background: $bg-lt-teal;
      border-bottom: 2px solid $teal;
      border-top: 2px solid $teal;
      padding: 20px 0 30px;

      @media (max-width: 767px) {
        padding: 15px 0 5px;
        border-top: 0;
      }

      h2 {
        margin-bottom: 30px;

        @media (max-width: 767px) {
          margin-bottom: 5px;
        }
      }

      h3 {
        margin-bottom: 5px;

        @media (max-width: 991px) {
          font-size: 22px;
        }
      }

      .living-option {
        border-top: 3px solid white;
        position: relative;

        &:nth-child(1) {
          border: none;
        }

        @media (max-width: 767px) {
          padding-top: 20px;

          &:nth-child(1) {
            padding-top: 0;
          }
        }

        header {
          @media (max-width: 767px) {
            position: absolute;
            height: 31px;
            top: -31px;
            width: calc(100% - 30px);
            text-align: center;
            background: rgba(66,75,131, 0.85);
            padding: 7px 5px;
          }

          h3 {
            @media (max-width: 767px) {
              color: white;
              margin: 0;
              font-size: 16px;
              line-height: 1;
            }
          }
        }

        .living-details {
          padding-top: 30px;

          @media (max-width: 991px) {
            padding: 0;
          }

          @media (max-width: 767px) {
            padding: 10px 15px;
          }

          p {
            padding-right: 30px;
            color: $blue;
            @extend .font-reg;
            letter-spacing: 0;

            @media (max-width: 767px) {
              margin-bottom: 5px;
              padding-right: 0;
            }
          }

          a {
            @extend .link-orange;
            color: $link-bg-orange;
            font-weight: 700;

            @media (max-width: 991px) {
              margin: 5px 0;
            }
          }
        }
      }
    }

    /* Explore */
    &.explore {
      background: $bg-lt-orange;
      padding: 0;
      margin: 25px 0;
      margin-top: 35px;
      border-top: 2px solid $lt-orange;
      border-bottom: 2px solid $lt-orange;

      @media (max-width: 767px) {
        margin: 25px -15px;
      }

      @media (max-width: 767px) {
        margin: 0 -15px;
      }

      .no-padding {
        padding: 0;
      }

      h2 {
        margin: 10px 0;
      }

      .map-wrapper {

        @media (max-width: 1199px) {
          padding-bottom:62.25%;
        }

        @media (max-width: 767px) {
          border-top: 2px solid white;
          border-bottom: 2px solid white;
        }
      }

      .location-details {
        padding-bottom: 30px;

        @media (max-width: 991px) {
          padding-bottom: 0;
        }
      }

      .explore-content {
        padding-left: 25px;
        padding-right: 15px;

        @media (max-width: 767px) {
          padding: 0;
          font-weight: 400;

          div {
            margin-left: 25px;
          }
        }

        header {
          padding-top: 20px;
        }

        h3 {
          font-size: 20px;
          line-height: 1.5;
          margin: 0;
        }

        p {
          line-height: 1.5;
          margin-bottom: 15px;

          a {
            color: $fontcolor;
            font-weight: 400;
            border: none;

            &:hover {
              color: $blue-hover;
              border: none;
            }
          }
        }

        .tx-license {
          p {
            font-weight: 300;
            font-size: 18px;
          }

          span {
            display: block;
          }
        }

        .address {
          position: relative;

          &:before {
            content: '';
            @extend .icon;
            background-position: -145px -61px;
            width: 18px;
            height: 26px;
            position: absolute;
            left: -25px;
            top: 3px;
          }
        }

        .phone {
          position: relative;

          &:before {
            content: '';
            @extend .icon;
            background-position: -79px -65px;
            width: 18px;
            height: 30px;
            position: absolute;
            left: -25px;
            top: 3px;
          }
        }

        .managed-by {
          position: absolute;
          bottom: 0;

          @media (max-width: 991px) {
            position: static;
          }

          p {
            font-weight: 400;
            font-size: 18px;
          }
        }

        .link-facebook {
          position: relative;

          &:before {
            content: '';
            @extend .icon;
            background-size: 450px 450px;
            background-position: -129px 0;
            width: 20px;
            height: 20px;
            position: absolute;
            left: -25px;
            top: 6px;
          }
        }
      }

      .btn {
        @media (max-width: 767px) {
          display: table;
          margin: 25px auto;
        }
      }
    }
  }

  /* Community Apartments */
  &.community-apartments {
    section {

      /* Hero */
      &.hero {
        .slide {
          .content {
            @media (max-width: 767px) {
              top: calc(50% - -1.5%);
            }

            @media (max-width: 374px) {
              padding: 10px;
            }
          }

          .hero-bg {
            padding-bottom: 32.8%;

            @media (max-width: 767px) {
              padding-bottom: 118%;
            }
          }
        }
      }

      &.apartment-intro {
        margin: 25px 0;

        @media (max-width: 767px) {
          margin: 25px -15px;
        }

        @media (max-width: 767px) {
          text-align: center;
        }

        .buttons {
          margin-top: 15px;

          @media (max-width: 991px) {
            margin-top: 0;
            text-align: center;
          }

          .btn {
            margin-left: 25px;
            margin-bottom: 10px;
            margin-top: 0;

            &:first-child() {
              margin-left: 0;
            }

            @media (max-width: 767px) {
              margin: 20px auto 5px;
              display: inline-block;
              display: table;
              width: 190px;

              &:first-child() {
                margin: 15px auto 0;
              }
            }
          }
        }
      }

      &.living-options-holder {
        border-bottom: none;
        margin: 0;
        padding: 30px 0;

        @media (max-width: 767px) {
          margin: 0 -15px;
        }

        @media (max-width: 767px) {
          border-top: 2px solid $teal;
          padding: 15px 0 0;
        }

        .living-details {
          @media (max-width: 1199px) {
            padding-top: 1%;
          }

          @media (max-width: 991px) {
            padding: 0 15px 0;
          }

          @media (max-width: 767px) {
            padding: 15px 15px 10px;
          }

          h3 {
            margin-bottom: 0;
          }

          p {
            color: $fontcolor;
            font-weight: 300;

            @media (max-width: 1199px) {
              padding-right: 0;
            }
          }
        }
      }

      &.explore {
        margin: 0 0 30px;

        @media (max-width: 767px) {
          margin: 0 -15px 10px;
        }
      }
    }
  }

  &.amenities {
    .living-vertical {
      margin-bottom: 35px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    .juicer {
      margin-bottom: 30px;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }
}

.community-galleries {
  display: none;
}
