.contact {

  .contact-container {
    margin: 45px -15px 10px;

    @media (min-width: 992px) {
      min-height: 750px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &.error {
      @media (min-width: 992px) {
        min-height: 1100px;
      }
    }
  }

  .contact-content  {

    @media (min-width: 992px) {
      position: relative;
    }

    & > * {
      &:not(.contact-form) {
        @media (min-width: 992px) {
          width: 41.666667%;
          padding-right: 30px;
        }
      }
    }

    p {
      margin-bottom: 26px;
    }
  }

  .contact-form {
    background-color: $bg-form;

    .gform_confirmation_wrapper {
      padding: 100px 30px 200px;
      margin: 0;
      color: $blue;
      font-size: 30px;
      font-weight: 300;
      line-height: 1.25;

      @media (max-width: 767px) {
        margin: 25px 0 5px;
        padding: 70px 15px 120px;
        font-size: 22px;
      }

      img {
        max-width: 250px;
        margin-right: 16.5%;

        @media (max-width: 767px) {
          max-width: 175px;
        }
      }

      .reset-link {
        margin: 10px 0 0;
      }

      a {
        @extend .inline-link;
      }
    }

    .gform_wrapper {
      background-color: $bg-form;
      padding: 12px 30px 20px;
      margin: 4px 0 0;

      .gform_heading {
        height: 0;
        overflow: hidden;
        margin-bottom: 0;
      }

      .gform_footer {

        @media (min-width: 768px) {
          text-align: left;
        }
      }

      ul.gform_fields li.gfield {
        &.permission-holder {
          width: 100% !important;
        }

        &.last-name {
          @media (min-width: 768px) {
            float: right;
          }
        }

        &:not(.gf_message) {

          @media (min-width: 768px) {
            width: calc(50% - 10px);
          }
        }
      }

      textarea {
        border: 0;
        font-weight: inherit;
        font-size: inherit;
      }

      .gf_captcha {

        label {
          height: 0;
          overflow: hidden;
        }

        .ginput_recaptcha {
          margin-top: -20px;
        }
      }
    }
  }

  .permission {
    font-style: italic;
    font-size: 16px;
    text-align: center;
    // padding: 0 30px;

    @media (min-width: 992px) {
      text-align: right;
    }

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }
}
