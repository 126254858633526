.blog-list {

  .hero {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;

    @media (max-width: 767px) {
      width: auto;
      margin-left: -15px;
      margin-right: -15px;
    }

    .slide {
      .content {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background: rgba(66,75,131, 0.8);
        padding: 15px;
        text-align: center;
        color: white;
        margin: 0;
        z-index: 1;

        h1 {
          margin: 0;
          line-height: 1.05;
        }

        @media (max-width: 767px) {
          top: calc(50%);
          transform: translateY(-50%);
          padding: 10px 15px;
          bottom: auto;
        }

        @media (max-width: 374px) {
          padding: 10px;
        }
      }

      .hero-bg {
        background-position: center;
        padding-bottom: 26%;

        @media (max-width: 767px) {
          padding-bottom: 85%;
        }
      }
    }
  }

  .taxonomy-form {
    @media (max-width: 767px) {
      margin: 0 -15px;
    }
  }

  form.blog-taxonomies {
    padding: 20px;
    background-color: #ecedf2;
    margin-top: 5px;

    @media (max-width: 767px) {
      padding: 13px 0 20px;
    }

    .form-group {
      margin: 0;
      border-left: 1px solid $blue;

      @media (max-width: 767px) {
        padding: 0;
        border: none;
        width: 50%;
      }

      &.right {
        @media (max-width: 767px) {
          margin-top: 10px;
        }
      }

      &:first-child {
        border: none;
        padding: 0 20px 0 0;

        @media (max-width: 767px) {
          padding: 0;
        }
      }
    }

    label {
      display: inline-block;
      color: $blue;
      font-size: 16px;
      margin: 0 15px 0 20px;

      @media (max-width: 767px) {
        display: block;
        margin: 0 0 3px;
        font-weight: 400;
      }
    }

    .form-wrap {
      position: relative;
      display: inline-block;

      @media (max-width: 767px) {
        width: 100%;
      }

      select {
        display: block;
        font-weight: 300;
        font-size: 18px;
        color: $fontcolor;
        height: 34px;
        line-height: 34px;
        padding: 0 10px;
        width: 100%;
        max-width: 100%;
        min-width: 180px;
        margin: 0;
        border-radius: 0;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%, 0 0;
        background-size: .65em auto, 100%;

        &::-ms-expand {
          display: none;
        }

        &:hover {
          border-color: #888;
        }

        &:focus {
          border-color: #aaa;
          box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
          box-shadow: 0 0 0 3px -moz-mac-focusring;
          color: #222;
          outline: none;
        }

        option {
          font-weight: 400;
        }

        *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
          background-position: left .7em top 50%, 0 0;
          padding: 5px 10px;
        }

        /* Disabled styles */
        // &:disabled, &[aria-disabled=true] {
        //   color: $fontcolor;
        //   background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        //     linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
        // }
        // &:disabled:hover, &[aria-disabled=true] {
        //   border-color: $fontcolor;
        // }
      }

      &.disabled {
        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        @extend .icon;
        width: 15px;
        height: 10px;
        background-position: -55px -41px;
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }
  }


  form {
    position: relative;
    margin-top: 15px;

    .form-container {
      align-items: center;
      justify-content: center;

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .subscription-label {
      display: block;
      margin-bottom: 10px;
    }

    input {
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-size: 18px !important;
      padding: 5px 10px;
      font-weight: 300;

      &::placeholder {
        color: $fontcolor;
      }

      @media (max-width: 767px) {
        padding: 6px 5px;
      }

      &.mce_inline_error {
        border: 1px solid $location-nav-orange;
      }
    }

    div.mce_inline_error {
      color: $location-nav-orange;
      font-size: 18.66px;
      line-height: 1.2;
      margin-top: 5px;
      font-weight: 700;
    }
  }

  .listing {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: 1.25;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 992px) {
      margin-left: 0;
      margin-right: 0;
    }

    .inner {
      padding: 0;
      height: 100%;
      background: $blue;
    }

    &-item {
      margin-top: 30px;

      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &-img {
      background-size: cover;
      background-position: center;
      height: 0;
      padding-bottom: 86%;

      @media (min-width: 992px) {
        height: 100%;
        min-height: 309px;
      }
    }
  }

  .thumbnail {
    position: relative;
    height: 100%;
    height: 300px;
    overflow: hidden;
  }

  .details {
    background: $blue;
      padding: 15px;
      color: white;
      line-height: 1.25;

    p {
      line-height: 1.25;
      margin-bottom: 0;

      &.main-cat {
        text-transform: uppercase;
        font-size: 16px;
        margin: 0 0 5px;
        font-weight: 400;
      }

      &.sub-cat {
        font-size: 16px;
        margin: 10px 0 0;
      }

      strong {
        font-weight: 400;
      }
    }
  }

  .subscription {
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    background: $bg-form;

    @media (min-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }

    &-container {
      position: relative;
      // top: 50%;
      // transform: translateY(-50%);
    }
  }
}
