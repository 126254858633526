@media print {
	.navbar {
    display: block !important;
  }
	.navbar-fixed-top {
    position: static !important;
  }
	body {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
	.nav-menu-secondary, .helpers,
  footer, #main-nav, #accommodations {
    display: none !important;
  }
	h1, h2, h3 {
    color: #e58523 !important;
  }
	.container {
    width: 100% !important;
  }
	p {
    font-size: 16px !important;
  }
	.col-md-8, .col-md-4 {
    width: 100% !important;
  }
	.location img {
    float: right !important;
  }
	.menu-tabs li {
    width: 30% !important;
    text-align: center;
    text-transform: uppercase;
  }
	.menu-item {
    display: block !important;
    float: left !important;
    width: 30% !important;
    text-align: center;
    padding: 5px 10px !important;
  }
	.menu-item .white-underline {
    text-transform: uppercase;
    padding: 10px 0 !important;
  }
	.menu-item.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
	.menu-item .col-sm-4 {
    float: none !important; width: 100% !important;
  }
	a[href]::after {
    content: none !important;
  }
  abbr[title]::after {
    content: none !important;
  }

  .visible-print {
    display: block;
  }
}
