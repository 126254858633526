header.site-header {
  padding: 25px 15px 15px;
  line-height: 1;
  background: white;
  position: fixed;
  z-index: 4000;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1230px;
  height: 145px;
  top: 0;
  left: 50%;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -o-transition: all .25s ease;
  -ms-transition: all .25s ease;
  transition: all .25s ease;

  &.sticky {
    height: 100px;
    padding: 20px 15px;

    .desktop-header {
      .desktop-search, .desktop-nav {
        height: 0;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 767px) {
    display: none;
  }

  .desktop-header {
    display: flex;

    .desktop-search {
      height: 40px;
      -webkit-transition: all .25s ease;
      -moz-transition: all .25s ease;
      -o-transition: all .25s ease;
      -ms-transition: all .25s ease;
      transition: all .25s ease;

      @media (max-width: 991px) {
        height: 39px;
      }
    }

    .desktop-nav {
      height: 40px;
      -webkit-transition: all .25s ease;
      -moz-transition: all .25s ease;
      -o-transition: all .25s ease;
      -ms-transition: all .25s ease;
      transition: all .25s ease;
    }

    .header-phone {
      text-align: right;

      a {
        color: $orange;
        font-size: 32px;
      }
    }

    .logo {
      padding-bottom: 15px;

      @media (max-width: 991px) {
        position: absolute;
        top: 25px;
        left: 0;
        z-index: 100;
      }

      a {
        width: 100%;
        max-width: 270px;
        display: inline-block;
        transition: .5s all;

        @media (max-width: 991px) {
          max-width: 270px;
        }

        @media (max-width: 767px) {
          width: 100%;
          max-width: 200px;
          margin-left: -7px;
          transition: none;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .header-details {
      text-align: right;

      @media (max-width: 991px) {
        position: absolute;
        top: 27px;
        right: 0;
      }

      .search-holder {
        margin: 8px 0 0;
        position: relative;
        overflow: hidden;

        .inner {
          padding: 0;
          overflow: hidden;
        }

        .search-toggle {
          width: 28px;
          height: 29px;
          padding: 10px;
          @extend .icon;
          background-position: 5px 3px;
          background-color: white;
          position: relative;
          top: 1px;
          right: 1px;
          border: 0;
          z-index: 11;
        }

        .desktop-search {
          background: white;
          width: 100%;
          max-width: 300px;
          position: absolute;
          top: 0;
          right: -300px;
          z-index: 10;
          -webkit-transition: all .25s ease;
          -moz-transition: all .25s ease;
          -o-transition: all .25s ease;
          -ms-transition: all .25s ease;
          transition: all .25s ease;

          &.open {
            right: 0;
          }

          .search-form {
            font-size: 16px;
            border: 1px solid $blue;

            input {
              &[type="search"] {
                float: left;
                width: calc(100% - 40px);
                padding: 5px 10px;
                max-height: 30px;
                line-height: 30px;
              }
            }

            .search-form-submit {
              float: right;
              display: inline-block;
              width: 24px;
              height: 20px;
              padding: 10px;
              @extend .icon;
              position: relative;
              top: 6px;
              right: 5px;
              border: 0;
            }
          }
        }
      }

      .main-nav-holder {
        .main-nav {
          margin: 0;

          li {
            display: inline;
            margin-left: 5px;

            @media (max-width: 1199px) {
              margin-left: 0;
            }

            &.dropdown {
              padding-bottom: 13px;

              // &:hover > .dropdown-menu {
              //   display: block;
              // }
            }

            .dropdown-toggle {
              &:after {
                display: none;
              }
            }

            &.menu-item-has-children {
              a {
                &:hover, &:focus {
                  background: $bg-gray;
                }
              }
            }

            &.current-menu-item, &.current-menu-ancestor {
              a {
                color: $dk-orange;
              }
            }

            > ul.dropdown-menu {
              background: $blue;
              border-radius: 0;
              min-width: 0;
              padding: 0;
              margin: 0;
              border: 1px solid white;
              border-top: none;

              li {
                display: block;
                margin: 0;
              }

              &.show {
                // margin-top: 2px;
                display: block;
              }

              .dropdown-header {
                display: block;
                padding: 7px 15px;
                margin: 15px 0 0 0;
                color: white;
                text-transform: uppercase;
                @extend .font-reg;

                &:nth-child(1) {
                  margin: 0;
                }
              }

              .dropdown-item {
                display: block;
                padding: 0;
                margin: 0;

                &:hover, &:focus, &.active, &.current-menu-ancestor, &.current-communities-ancestor {
                  background: $active-blue;
                }

                a {
                  padding: 8px 15px;
                  color: white;

                  &:hover {
                    background: transparent;
                  }
                }
              }
            }

            a {
              color: $blue;
              font-size: 16px;
              font-weight: 400;
              display: inline-block;
              padding: 15px 15px;
              text-decoration: none;

              @media (max-width: 1199px) {
                padding: 15px 10px;
              }

              &:hover, &:focus {
                // background: $bg-gray;
                color: $dk-orange;
              }
            }

            &.nav-locations {
              .dropdown-toggle {
                background: $bg-gray;
                padding: 6px 10px 6px 10px;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 400;
                color: $location-nav-orange;

                &:hover, &:focus   {
                  padding: 15px 10px 15px 10px;
                }

                &:after {
                  content: '';
                  @extend .icon;
                  height: 10px;
                  width: 15px;
                  display: inline-block;
                  margin-left: 5px;
                  background-position: -55px -41px;
                  border: 0;
                  vertical-align: inherit;
                }
              }

              > ul.dropdown-menu {
                @extend .dropdown-menu-right;
                color: $location-nav-orange;
                right: 0;
                left: auto !important;
                max-height: 420px;
                overflow: auto;

                .dropdown-item {
                  padding-left: 15px;
                  color: white;

                  &.all-locations {
                    padding-left: 0;
                  }

                  &:hover, &:focus, &.active, &.current-menu-ancestor, &.current-communities-ancestor {
                    background: $active-blue;
                  }
                }
              }

            }
          }
        }
      }
    }

    &.sticky {

    }
  }
}

header.mobile-site-header {
  padding: 0;
  line-height: 1;
  background: white;
  position: fixed;
  z-index: 100;
  transform: translateX(-50%);
  width: 100%;
  height: 100px;
  top: 0;
  left: 50%;
  display: none;

  @media (max-width: 767px) {
    display: flex;
  }

  .mobile-header {
    height: 100px;
    position: relative;
    padding-bottom: 23px;

    .mobile-logo {
      padding-bottom: 3px;

      @media (max-width: 374px) {
        padding-right: 0;
      }

      a {
        width: 100%;
        max-width: 200px;
        display: inline-block;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .menu-btn {
      position: absolute;
      top: 10px;
      right: 15px;
      @extend .icon;
      background-position: 0 -66px;
      width: 24px;
      height: 20px;
      display: block;
    }

    .header-details {
      position: relative;

      .header-phone {
        text-align: right;

        a {
          font-size: 18.66px;
          color: $dk-orange;

          @media (max-width: 374px) {
            font-size: 16px;
          }
        }
      }
    }

    .mobile-nav-holder {
      width: 100%;
      background: white;
      padding: 50px 15px 55px;
      min-height: 80vh;
      max-height: 100vh;
      overflow: auto;
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 9000;
      font-size: 20px;
      border-bottom: 4px solid $orange;
      -webkit-transition: all .25s ease;
      -moz-transition: all .25s ease;
      -o-transition: all .25s ease;
      -ms-transition: all .25s ease;
      transition: all .25s ease;

      &.active {
        left: 0;
      }

      .menu-btn {
        position: absolute;
        top: 10px;
        right: 15px;
        @extend .icon;
        background-position: -98px -34px;
        width: 24px;
        height: 20px;
        display: block;
      }

      .mobile-nav {
        font-size: 20px;

        > li {
          margin-bottom: 15px;

          a {
            color: $blue;
            font-weight: 700;
            @extend .font-reg;

            &:hover, &:active, &.active {
              color: $orange;
            }
          }

          &.current-menu-item {
            a {
              color: $orange;
            }
          }

          &.current-menu-parent {
            a {
              color: $orange;

              &:after {
                border-top: 4px solid $orange;
              }
            }
          }
        }

        .menu-item-has-children {
          position: relative;

          &:hover, &:active {
            > a {
              color: $orange;

              &:after {
                border-top: 4px solid $orange;
              }
            }
          }

          > a {
            display: inline-block;

            &:after {
              content: '';
              margin-left: 7px;
              position: relative;
              top: 2px;
              display: inline-block;
              border: 4px solid transparent;
              border-top: 4px solid $blue;
            }
          }
        }

        .sub-menu {
          display: none;
          overflow: hidden;
          margin: 15px -15px 0 -15px;
          padding: 0;
          position: relative;
          background: $blue;

          li {
            padding: 0 15px;

            &:hover, &:active, &.active {
              background: $orange;
            }

            a {
              color: white;
              font-weight: 700;
              display: block;
              padding: 10px 0;
            }

            &.current-menu-item, &.current-menu-ancestor, &.current-communities-ancestor {
              background: $orange;

              a {
                color: white;
              }
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 15%;
            border: 10px solid transparent;
            border-top: 10px solid white;

            @media (max-width: 374px) {
              left: 15px;
            }
          }
        }
      }

      .utility {
        .btn {
          font-size: 18.66px;
          min-width: 214px;
          text-align: left;
          display: table;
          padding: 10px 15px;
          margin-top: 30px;
          position: relative;

          &.chat-btn {
            &:after {
              content: '';
              @extend .icon;
              width: 30px;
              height: 24px;
              background-position: -55px -102px;
              position: absolute;
              top: 10px;
              right: 10px;
            }
          }
        }
      }

      .mobile-search {
        border: 1px solid $blue;
        width: 100%;
        margin-top: 30px;

        .search-form {
          font-size: 16px;

          input {
            &[type="search"] {
              float: left;
              width: calc(100% - 40px);
              padding: 7px 10px;
            }
          }

          ::placeholder {
            color: $fontcolor;
          }

          .search-form-submit {
            float: right;
            display: inline-block;
            width: 24px;
            height: 20px;
            padding: 10px;
            @extend .icon;
            position: relative;
            top: 6px;
            right: 5px;
            border: 0;
          }
        }
      }
    }
  }
}
