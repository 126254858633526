.communities {
  &.community-dining {

    section.hero {
      .hero-bg {
        padding-bottom: 33%;
      }

      @media (max-width: 767px) {
        .hero-bg {
          padding-bottom: 118%;
        }
        .content {
          top: calc(50% + 2.2%);
        }
      }
    }

    section.video {
      @media (max-width: 767px) {
        margin: 25px -15px 20px -15px;
      }

      ul.menu-list {
        list-style: none;
        width: auto;
        padding: 25px 0 0 0;
        margin-bottom: 0;

        @media (max-width: 767px) {
          padding: 15px 0 0 0;
          margin-bottom: 30px;
        }

        li {
          margin-bottom: 20px;
          display:flex;

          @media (max-width: 767px) {
            margin-bottom: 15px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          display: flex;
          text-transform: uppercase;
          @extend .font-reg;

          &::before {
            content: "";
            background: url(img/icon_menu.png);
            background-repeat: no-repeat;
            background-size: 100%;
            width: 26px;
            height:32px;
            color: $orange;
            font-weight: bold;
            display: inline-flex;
            margin-right: 15px;
            position: relative;
            top: -4px;
          }
        }

      }

      .video_thumbnail {
        position: relative;

        .play_btn {
          display: block;
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // background-image: url(img/btn_play.png);
          // background-repeat: no-repeat;
          // background-size: 125px;
          // background-position: 50%;
          z-index: 6;
        }
      }
    }

    section.dining-areas {
      margin: 0 0 50px;

      @media (max-width: 767px) {
        margin: 0 -15px 40px;
      }

      .dining-area {
        margin: 15px -15px;

        p.area-title {
          font-size: 20px;
          color: $orange;
          font-weight: 700;
          margin: 0 0 5px;
          line-height: 1.2;
        }

        img {
          margin-top: 5px;

          @media (max-width: 767px) {
            margin: 15px 0 10px;
          }
        }
      }
    }

    section.menu-options {
      margin: 0;
      @extend .h-100;
      border-bottom: 2px solid orangered;
      padding-bottom: 0;

      @media (max-width: 767px) {
        border-top: 2px solid orangered;
      }

      .col-md:not(:last-child) {
        border-right: 2px solid white;
        @media (max-width: 767px) {
          border: none;
        }
      }
        @media (max-width: 767px) {
          width: auto;
          margin-left: -15px;
          margin-right: -15px;
        }

        .menu-option {
          background: none;
          border-radius: 0;
          border: none;
          background-color: $bg-lt-orange;

          .overlay-header {
            position: relative;
            max-height: 209px;
            overflow: hidden;

            h3 {
              position: absolute;
              bottom: 0;
              left: 0;
              background: rgba($blue, 0.75);
              width: 100%;
              color: white;
              margin: 0;
              padding: 5px 4.5%;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 20px;
              text-shadow: 1px 1px 8px rgba(0,0,0, 0.75);

              @media (max-width: 991px) {
                font-size: 1.1em;
                padding: 5px 8%;
              }

              @media (max-width: 767px) {
                text-align: center;
                font-size: 18.66px;
                padding: 8px 15px 12px;
              }
            }
          }

          .card-body {
            padding: 20px;
            ul {
              list-style: none;
              li::before {
                content: "\2022";
                color: $orange;
                font-weight: bold;
                display: inline-block;
                width: 1em;
              }
            }
          }

        }
      }

    .chef-details {
      h4 {
        color: $orange;
        font-size: 1rem;
        font-weight: 700;

        @media (max-width: 767px) {
          margin: 10px 0 0;
        }
      }
    }

    section.compliments {
      margin: 30px 0 10px;

      @media (max-width: 767px) {
        margin: 15px -15px;
      }

      h2 {
        @media (max-width: 767px) {
          font-size: 32px;
          line-height: 1.25;
        }
      }
    }

    #videoModal {
      z-index: 100000;
      .modal-body {
        position:relative;
        padding:0px;
      }
      .close {
        position:absolute;
        right: -15px;
        top:-45px;
        z-index:999;
        font-size:2rem;
        font-weight: normal;
        color:#fff;
        opacity:1;
        padding: 5px 15px;
        text-shadow: 2px 2px 4px rgba(0,0,0,.75);
      }
      .modal-dialog {
        max-width: 800px;
        margin: 80px auto 30px;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }

      @media (max-width: 767px) {
        padding: 15px!important;
        padding-right: 50px!important;
      }


    }

  }

}
